import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import useOnClickOutside from "../../../hooks/useClickOutside";
import useClientPosition from "../../../hooks/useClientPosition";
import { hideModal } from "../../../store/slices/modalSlice";
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
import MODAL from "../../../utils/constants/modal";
import SvgButton from "../../shared/SvgButton";

import styles from "./index.module.scss";
import CancellationPolicyModal from "./modals/CancellationPolicyModal";
import DeckPlansModal from "./modals/DeckPlansModal";
import ImagesCarouselModal from "./modals/ImagesCarouselModal";
import ItineraryModal from "./modals/ItineraryModal";
import PaymentModal from "./modals/PaymentModal";
import StateroomsModal from "./modals/StateroomsModal";

const MODALS_BY_TYPE = {
  [MODAL.MODAL_TYPES.ITINERARY]: ItineraryModal,
  [MODAL.MODAL_TYPES.IMAGES]: ImagesCarouselModal,
  [MODAL.MODAL_TYPES.STATEROOMS]: StateroomsModal,
  [MODAL.MODAL_TYPES.DECK_PLANS]: DeckPlansModal,
  [MODAL.MODAL_TYPES.CANCELLATION_POLICY]: CancellationPolicyModal,
  [MODAL.MODAL_TYPES.PAYMENT]: PaymentModal,
};

// Functional component to dynamically render modal content based on type prop.
function Modal({ type }: { type: string }) {
  // Resolve Component to render from MODALS_BY_TYPE or default to a div.
  const Component = MODALS_BY_TYPE[type] ?? "div";

  // Render the resolved Component.
  return <Component />;
}

// Container component for modals, managing visibility and content based on Redux state.
function ModalContainer({ children }: React.PropsWithChildren) {
  // Redux dispatch hook.
  const dispatch = useTypedDispatch();
  // Hooks to manage scroll position.
  const { savePosition, restorePosition } = useClientPosition();

  // Current modal type from Redux state.
  const { type, size } = useTypedSelector((state) => state.modal);

  // Ref for detecting clicks outside the modal to close it.
  const outsideRef = useRef(null);

  // Internal state to manage animation timing for modal visibility.
  const [internalType, setInternalType] = useState(type);

  // Function to handle modal closure.
  const handleCloseModal = () => {
    if (internalType) {
      dispatch(hideModal());
      restorePosition();
    }
  };

  // Hook to close modal on click outside.
  useOnClickOutside(outsideRef, handleCloseModal);

  // Effect to update internalType state for animations, saving position on modal open.
  useEffect(() => {
    if (type) {
      savePosition();
    }

    setTimeout(
      () => {
        setInternalType(type);
      },
      type ? 0 : 300, // Delay for closing animation.
    );
  }, [type]);

  // Render the modal container with conditional styling for visibility.
  return (
    <div
      className={classNames(styles.container, {
        [styles.container_active]: internalType, // Apply active styling if a modal type is set.
      })}>
      {children}

      <div
        className={classNames(styles.modal, {
          [styles.modal_active]: internalType, // Apply active styling if a modal type is set.
        })}>
        <div
          ref={outsideRef} // Attach ref for click outside detection.
          className={classNames(styles.content, {
            [styles.content_active]: type, // Apply active styling based on modal type.
            [styles.content_sm]: size === "sm" || !size,
            [styles.content_md]: size === "md",
            [styles.content_lg]: size === "lg",
          })}>
          <div className={styles.header}>
            {/* Display the type of modal as the title. */}
            <span className={styles.headerTitle}>{internalType}</span>

            {/* Icon to close the modal, with click handler. */}
            <SvgButton
              icon="FiX"
              className={styles.closeIcon}
              onClick={handleCloseModal}
            />
          </div>

          {/* Render the modal content dynamically based on internalType. */}
          <Modal type={internalType} />
        </div>
      </div>
    </div>
  );
}

// Export the ModalContainer for use in the application.
export default ModalContainer;
