import { useTypedSelector } from "../../../../../store/store";
import CustomCarousel from "../../../../shared/Carousel";

import styles from "./index.module.scss";

// Define the functional component to display a modal with a carousel of images.
function ImagesCarouselModal() {
  // Retrieve modal data from the Redux store, expecting an array of image URLs.
  const { data } = useTypedSelector((state) => state.modal);

  // Render the modal content, passing the image URLs to the CustomCarousel component.
  return (
    <div className={styles.container}>
      <CustomCarousel
        items={(data as string[]) ?? []}
        slideClassName={styles.slide}
      />
    </div>
  );
}

// Export the component for use elsewhere.
export default ImagesCarouselModal;
