import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useLazyInitReservationListQuery } from "../../../store/services/ReservationService";
import { ReservationListInterface } from "../../../store/slices/reservationSlice";
import { useTypedSelector } from "../../../store/store";
import { SEARCH } from "../../../utils/constants/routes";
import LoadingContainer from "../../containers/LoadingContainer";
import Alert from "../../shared/Alert";
import DatePicker, { DatePickerHandler } from "../../shared/DatePicker";
import Input from "../../shared/Input";

import styles from "./index.module.scss";
import Content from "./molecules/Content";

interface InputInterface {
  value: string[];
  errorMessage?: string;
}

interface SortOnInterface {
  type: string;
  first: boolean;
}

interface SearchInputsInterface {
  [key: string]: InputInterface;
  bookingName: InputInterface;
  embarkDate: InputInterface;
  yachtName: InputInterface;
}

function CurrentBookings() {
  const [getReservations] = useLazyInitReservationListQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    current_page_title,
    yacht_name_placeholder,
    current_booking_payment_info,
  } = useTypedSelector((state) => state.environment);

  const [isReservationLoading, setReservationLoading] = useState(true);
  const session = useTypedSelector((state) => state.session);

  const bookingList = useTypedSelector(
    (state) => state.reservation.reservationList,
  );

  const [bookingListState, setBookingListState] = useState<
    ReservationListInterface[]
  >([]);

  const [searchInputs, setSearchInputs] = useState<SearchInputsInterface>({
    bookingName: { value: [""] },
    yachtName: { value: [""] },
    embarkDate: {
      value: [],
      errorMessage: "",
    },
  });

  const datePickerRef = useRef<DatePickerHandler>(null);

  const checkDateFilter = (
    startDate: string,
    endDate: string,
    checkDate: string,
  ) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const check = new Date(checkDate);

    return check >= start && check <= end;
  };

  const [filterBookingList, setFilterBookingList] = useState<
    ReservationListInterface[]
  >([]);

  const isUsingFilter = useMemo(() => {
    for (const key in searchInputs) {
      const value = searchInputs[key].value;

      if (value.length !== 0 && value[0] !== "") {
        return true;
      }
    }

    return false;
  }, [searchInputs]);

  const [sortOn, setSortOn] = useState({ type: "", first: false });

  const initReservations = () => {
    getReservations({
      channel: "CS",
      channel_partner: session.agency,
      agency: session.agent,
      embarkation_from: dayjs().format("YYYY-MM-DD"),
    })
      .then((res) => {
        if (!res.data) {
          return;
        }

        setBookingListState(res.data);
      })
      .finally(() => {
        setReservationLoading(false);
      });
  };

  const sortByCriteria = (
    data: ReservationListInterface[],
    type: string,
    sortOn: SortOnInterface,
    setSortOn: (args: SortOnInterface) => void,
    sortFunction: (
      a: ReservationListInterface,
      b: ReservationListInterface,
    ) => number,
  ) => {
    if (sortOn.type === type) {
      const sortedData = data.sort(sortFunction);

      setSortOn({ type, first: !sortOn.first });

      return sortedData;
    } else {
      const sortedData = data.sort(sortFunction);

      setSortOn({ type, first: false });

      return sortedData;
    }
  };

  const sortBookingList = (type: string) => {
    switch (type) {
      case "passenger_name": {
        setFilterBookingList((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) =>
              !sortOn.first
                ? a.lead_lastname.localeCompare(b.lead_lastname)
                : b.lead_lastname.localeCompare(a.lead_lastname),
          ),
        );

        setBookingListState((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) =>
              !sortOn.first
                ? a.lead_lastname.localeCompare(b.lead_lastname)
                : b.lead_lastname.localeCompare(a.lead_lastname),
          ),
        );

        break;
      }

      case "embarkation_date":
      case "booking_date": {
        setFilterBookingList((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) => {
              const dateA = dayjs(a.embarkation_date);
              const dateB = dayjs(b.embarkation_date);

              return !sortOn.first ? dateA.diff(dateB) : dateB.diff(dateA);
            },
          ),
        );

        setBookingListState((prevList) =>
          sortByCriteria(
            [...prevList],
            type,
            sortOn,
            setSortOn,
            (a: ReservationListInterface, b: ReservationListInterface) => {
              const dateA = dayjs(a.embarkation_date);
              const dateB = dayjs(b.embarkation_date);

              return !sortOn.first ? dateA.diff(dateB) : dateB.diff(dateA);
            },
          ),
        );

        break;
      }

      default: {
        break;
      }
    }
  };

  const handleInputChange = ({
    value,
    valueKey,
  }: {
    value: string | string[];
    valueKey?: string;
  }) => {
    if (valueKey) {
      setSearchInputs((prev: SearchInputsInterface) => {
        return {
          ...prev,
          [valueKey]: {
            errorMessage: "",
            value: Array.isArray(value) ? value : [value],
          },
        };
      });
    }
  };

  const resetFiltersHandler = () => {
    setSearchInputs({
      bookingName: { value: [""] },
      embarkDate: {
        value: [],
        errorMessage: "",
      },
      yachtName: { value: [""] },
      expeditionType: { value: [] },
    });

    datePickerRef.current?.handleResetDate();
  };

  useEffect(() => {
    const filterTotal = Array.isArray(bookingList)
      ? bookingList.filter((item) => {
          const checkBookingName = searchInputs.bookingName.value[0]
            ? (item.lead_lastname ?? "")
                .toLowerCase()
                .includes(searchInputs.bookingName.value[0].toLowerCase())
            : false;

          const checkYachName = searchInputs.yachtName.value[0]
            ? item.ship_name
                .toLowerCase()
                .includes(searchInputs.yachtName.value[0].toLowerCase())
            : false;

          const checkDate = checkDateFilter(
            searchInputs.embarkDate.value[0],
            searchInputs.embarkDate.value[1],
            item.embarkation_date,
          );

          return checkBookingName || checkYachName || checkDate;
        })
      : [];

    setFilterBookingList(filterTotal);
  }, [searchInputs]);

  useEffect(initReservations, []);

  return (
    <LoadingContainer isLoading={isReservationLoading}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.filtersHeader}>
            <h1>{current_page_title}</h1>

            <button
              className={styles.startBooking}
              onClick={() => navigate(SEARCH)}>
              {t("START A BOOKING")}
            </button>
          </div>

          {Boolean(current_booking_payment_info) && (
            <Alert message={current_booking_payment_info} />
          )}

          <div className={styles.filtersHeader}>
            <span>{t("Click on a booking to find out more information.")}</span>
          </div>

          <div className={styles.filtersHeader}>
            <span>{t("FILTER BOOKINGS BY")}</span>

            <button
              className={styles.resetFilters}
              onClick={resetFiltersHandler}>
              {t("RESET FILTERS")}
            </button>
          </div>

          <form className={styles.form}>
            <Input
              value={searchInputs.bookingName.value[0]}
              valueKey="bookingName"
              placeholder={t("by name")}
              errorMessage={searchInputs.bookingName.errorMessage}
              onChange={handleInputChange}
              className={styles.input}
            />

            <DatePicker
              valueKey="embarkDate"
              placeholder={t("embark date")}
              returnFormat="YYYY-MM"
              defaultDate={searchInputs.embarkDate.value}
              errorMessage={searchInputs.embarkDate.errorMessage}
              onChange={handleInputChange}
              ref={datePickerRef}
              className={styles.inputDate}
              mode="range"
              minDate={dayjs(dayjs().subtract(1, "month"))}
            />

            <Input
              value={searchInputs.yachtName.value[0]}
              valueKey="yachtName"
              placeholder={yacht_name_placeholder}
              errorMessage={searchInputs.yachtName.errorMessage}
              onChange={handleInputChange}
              className={styles.input}
            />
          </form>

          <Content
            bookingList={isUsingFilter ? filterBookingList : bookingListState}
            sortBookingList={sortBookingList}
            onModalClose={initReservations}
          />
        </div>
      </div>
    </LoadingContainer>
  );
}

export default CurrentBookings;
