import { nanoid } from "@reduxjs/toolkit"; // Importing nanoid function from Redux Toolkit
import classNames from "classnames"; // Importing classNames function
import { useMemo } from "react"; // Importing useMemo hook from React

import styles from "./index.module.scss"; // Importing CSS module styles

interface IInputProps {
  className?: string; // Optional className prop for custom styling
  value: string; // Value of the textarea
  valueKey?: string; // Key to identify the value
  label?: string; // Label text for the textarea
  subtext?: string; // Subtext for additional information
  placeholder?: string; // Placeholder text for the textarea
  autoComplete?: string; // Autocomplete attribute for the textarea
  name?: string; // Name attribute for the textarea
  type?: string; // Type attribute for the textarea
  errorMessage?: string; // Error message to display
  isRequired?: boolean; // Flag to indicate if the textarea is required
  readOnly?: boolean; // Flag to indicate if the textarea is read-only
  showError?: boolean; // Flag to indicate if error should be shown
  onClick?: () => void; // Function to handle click event
  onChange?: ({ value, valueKey }: { value: string; valueKey: string }) => void; // Function to handle change event
  onBlur?: ({ value, valueKey }: { value: string; valueKey: string }) => void; // Function to handle blur event
}

/**
 * Renders a textarea input field with optional label, subtext, and error message.
 *
 * @param {string} className - Additional CSS class names for styling.
 * @param {string} value - The value of the textarea input.
 * @param {string} valueKey - The key for the value.
 * @param {string} label - The label for the input field.
 * @param {string} subtext - Additional text to display below the input field.
 * @param {string} placeholder - The placeholder text for the input field.
 * @param {string} autoComplete - Specifies whether the browser should autocomplete the input field.
 * @param {string} name - The name attribute of the input field.
 * @param {string} errorMessage - Error message to display when input is invalid.
 * @param {boolean} isRequired - Specifies if the input is required.
 * @param {boolean} readOnly - Specifies if the input is read-only.
 * @param {boolean} showError - Specifies if the error message should be displayed.
 * @param {function} onClick - Function to handle click events on the input.
 * @param {function} onChange - Function to handle changes in the input value.
 * @param {function} onBlur - Function to handle blur events on the input.
 * @returns {JSX.Element} - The rendered TextArea component.
 */
function TextArea({
  className,
  value,
  valueKey,
  label,
  subtext,
  placeholder,
  autoComplete,
  name,
  errorMessage,
  isRequired,
  readOnly,
  showError,
  onClick,
  onChange,
  onBlur,
}: IInputProps) {
  const id = useMemo(nanoid, []); // Generate a unique ID for the textarea

  // Function to handle click event.
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // Function to handle change event.
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange({ value: e.target.value, valueKey: valueKey ?? "" });
    }
  };

  // Function to handle blur event.
  const handleBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur({ value: e.target.value, valueKey: valueKey ?? "" });
    }
  };

  return (
    // Render the container div
    <div className={classNames(styles.container, className)}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
          {isRequired && <span className={styles.asterisk}>*</span>}
        </label>
      )}

      {subtext && (
        // Render the subtext if provided
        <p className={classNames(styles.subtext, styles.subtext_small)}>
          {subtext}
        </p>
      )}

      {/* Render the textarea field container */}
      <div className={styles.fieldContainer}>
        <textarea
          id={id}
          className={classNames(styles.field, {
            [styles.field_error]: !!errorMessage,
          })}
          value={value}
          placeholder={placeholder}
          name={name}
          readOnly={readOnly}
          autoComplete={autoComplete}
          onClick={handleClick}
          onChange={handleChange}
          onBlur={handleBlur}></textarea>
      </div>

      {showError && <span className={styles.error}>{errorMessage}</span>}
    </div>
  );
}

// Default props
TextArea.defaultProps = {
  className: "",
  valueKey: "",
  label: "",
  placeholder: "",
  autoComplete: "",
  name: "",
  errorMessage: "",
  isRequired: false,
  readOnly: false,
  showError: true,
  handleClick: () => {},
  onChange: () => {},
};

// Export the TextArea component
export default TextArea;
