// React's effect hook is imported for side effects.
import { useEffect } from "react";

// Custom hooks for accessing the Redux dispatch function and state selection.
import { setEnvVariables } from "../../../store/slices/environmentSlice";
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
// The action creator for setting environment variables in the Redux state.
// A predefined set of environment variables.
import LoadingContainer from "../../containers/LoadingContainer";

import { ENV_VARS } from "./utils/constants";

// A component that displays a loading indicator or its children based on a condition.

// The EnvironmentGuard component ensures that the app's environment variables are loaded.
function EnvironmentGuard({ children }: React.PropsWithChildren) {
  // Hook to dispatch actions to the Redux store.
  const dispatch = useTypedDispatch();

  // Retrieves the environment slice from the Redux store to check if it's initialized.
  const { isInitialized } = useTypedSelector((state) => state.environment);

  // Function to dispatch the setEnvVariables action, which initializes environment variables.
  const initEnvVariables = () => {
    dispatch(setEnvVariables(ENV_VARS));
  };

  // Effect hook to delay the initialization of environment variables by 2 seconds after component mount.
  useEffect(() => {
    setTimeout(initEnvVariables, 2000);
  }, []); // Dependency array is empty, so this effect runs only once after the initial render.

  // Renders a LoadingContainer that shows its children if the environment is initialized, otherwise shows loading indicator.
  return (
    <LoadingContainer isLoading={!isInitialized}>{children}</LoadingContainer>
  );
}

// Exports the EnvironmentGuard for use as a wrapper in the app, ensuring environment variables are loaded.
export default EnvironmentGuard;
