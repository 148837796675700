import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  createAgency,
  createAgent,
} from "../../../store/actions/agencyActions";
import { AgencyAPI } from "../../../store/services/AgencyService";
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
import citizenshipList from "../../../utils/constants/citizenshipList";
import { AGENT_REGISTRATION } from "../../../utils/constants/routes";
import titleList from "../../../utils/constants/titleList";
import i18n from "../../../utils/i18n";
import { validateAgencyRegistration } from "../../../utils/validation";
import Button from "../../shared/Button";
import CustomLink from "../../shared/CustomLink";
import CustomSelect from "../../shared/CustomSelect";
import Input from "../../shared/Input";
import RadioGroup from "../../shared/RadioGroup";
import SearchInput from "../../shared/SearchInput";

import styles from "./index.module.scss";
import {
  IAgencyInputs,
  IValidationAgencyInputs,
  InputChangeHandler,
} from "./types";

const ADDRESS_RADIO_GROUP = {
  0: { value: "different", label: i18n.t("a different address") },
  1: { value: "same", label: i18n.t("no, it's the same") },
};

// The main component for agency registration, utilizing form elements for input.
function AgencyRegistration() {
  const dispatch = useTypedDispatch(); // Hook for dispatching actions to the Redux store.
  const { t } = useTranslation(); // Hook for accessing the translation function.

  // Selector hook to get data from the Redux store.
  const { consortia, markets, regulatory } = useTypedSelector(
    (state) => state.agency,
  );

  const { agency_commission, api_accounts, agency_account_type, agency_name } =
    useTypedSelector((state) => state.environment);

  // State hook for managing form submission loading state.
  const [isLoading, setIsLoading] = useState(false);
  const [titleListForm, setTitleListForm] = useState(titleList);
  // State hook for managing form inputs and their error messages.
  const [inputs, setInputs] = useState<IAgencyInputs>({
    name: { value: "", errorMessage: "" },
    address: {
      address: { value: { name: "address", value: "" }, errorMessage: "" },
      city: { value: { name: "city", value: "" }, errorMessage: "" },
      state: { value: { name: "state", value: "" }, errorMessage: "" },
      zip: { value: { name: "zip", value: "" }, errorMessage: "" },
      country: { value: { name: "country", value: "" }, errorMessage: "" },
    },
    telephone: { value: "", errorMessage: "" },
    website: { value: "", errorMessage: "" },
    regulatory_type: { value: "", errorMessage: "" },
    regulatory: { value: "", errorMessage: "" },
    consortia: { value: "", errorMessage: "" },
    market: { value: "", errorMessage: "" },
    title: { value: "", errorMessage: "" },
    firstName: { value: "", errorMessage: "" },
    surname: { value: "", errorMessage: "" },
    agent_telephone: { value: "", errorMessage: "" },
    position: { value: "", errorMessage: "" },
    isSameAddress: { value: "same", errorMessage: "" },
    agent_address: {
      address: { value: { name: "address", value: "" }, errorMessage: "" },
      city: { value: { name: "city", value: "" }, errorMessage: "" },
      state: { value: { name: "state", value: "" }, errorMessage: "" },
      zip: { value: { name: "zip", value: "" }, errorMessage: "" },
      country: { value: { name: "country", value: "" }, errorMessage: "" },
    },
    email: { value: "", errorMessage: "" },
    b2b_password: { value: "", errorMessage: "" },
    confirm_b2b_password: { value: "", errorMessage: "" },
  });

  // Preparing lists for form select inputs.
  const countryList = useMemo(() => {
    const countryList = citizenshipList
      .map((el) => ({
        label: el.country,
        value: el.iso_2,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    return countryList;
  }, []);

  const [countryListForm, setCountryListForm] = useState(countryList);

  // Memoized options for consortia, markets and regulatory, derived from Redux store data.
  const consortiaOptions = useMemo(
    () => consortia.map((el) => ({ value: el.code, label: el.name })),
    [consortia],
  );

  const marketsOptions = useMemo(
    () => markets.map((el) => ({ value: el.code, label: el.name })),
    [markets],
  );

  const regulatoryOptions = useMemo(
    () => regulatory.map((el) => ({ value: el.code, label: el.name })),
    [regulatory],
  );

  // Function to initialize consortia and markets data from the server.
  const init = () => {
    dispatch(AgencyAPI.endpoints.initConsortia.initiate(null));
    dispatch(AgencyAPI.endpoints.initMarkets.initiate(null));
    dispatch(AgencyAPI.endpoints.initRegulatory.initiate(null));
  };

  // Handler for creating an agent based on created agency.
  const handleCreateAgent = async (
    validData: IValidationAgencyInputs,
    agency: number,
  ) => {
    setIsLoading(true); // Begin loading.

    const address = Object.entries(inputs.agent_address).map(
      ([_, value]) => value.value,
    );

    const payload = {
      ...validData,
      address,
      agency,
      code: nanoid(16),
      is_web: true,
      is_default: true,
      skip_external: true,
      name: `${inputs.firstName.value} ${inputs.surname.value}`,
      sales_area: null,
      telephone: inputs.agent_telephone.value,
    };

    const {
      website,
      regulatory_type,
      regulatory,
      consortia,
      market,
      title,
      agent_address,
      agent_telephone,
      confirm_b2b_password,
      ...finalPayload
    } = payload;

    await dispatch(createAgent(finalPayload));

    setIsLoading(false); // End loading.
  };

  // Handler for creating an agency based on valid form data.
  const handleCreateAgency = async (validData: IValidationAgencyInputs) => {
    setIsLoading(true); // Start loading indicator.

    const address = Object.entries(inputs.address).map(
      ([_, value]) => value.value,
    );

    const payload = {
      ...validData,
      address,
      account_type: agency_account_type,
      api_accounts: [api_accounts],
      code: nanoid(16),
      commission_level: agency_commission,
      credit_limit: 0,
      sales_area: null,
    };

    const {
      agent_telephone,
      confirm_b2b_password,
      firstName,
      position,
      regulatory_type,
      surname,
      ...finalPayload
    } = payload;

    try {
      const createdAgency = await dispatch(createAgency(finalPayload));

      await handleCreateAgent(validData, createdAgency.payload as number);
    } catch (error) {
      console.log("handleCreateAgency error: ", error);

      throw error;
    } finally {
      setIsLoading(false); // Stop loading indicator.
    }
  };

  const handleAddressChange = ({
    value,
    valueKey,
    targetKey,
  }: InputChangeHandler & { targetKey: "address" | "agent_address" }) => {
    const typedValueKey = valueKey as
      | "address"
      | "city"
      | "state"
      | "zip"
      | "country";

    setInputs((prev) => ({
      ...prev,
      [targetKey]: {
        ...prev[targetKey],
        [typedValueKey]: {
          ...prev[targetKey][typedValueKey],
          value: { ...prev[targetKey][typedValueKey].value, value },
          errorMessage: "",
        },
      },
    }));
  };

  // Handlers for managing form input changes.
  const handleInputChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));

    if (valueKey === "isSameAddress" && value === "same") {
      setInputs((prev) => ({
        ...prev,
        agent_address: structuredClone(prev.address),
      }));
    }
  };

  // Function to handle search input changes and filter the dropdown lists accordingly.
  const handleSearchInputChange = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey?: string;
  }) => {
    switch (valueKey) {
      case "title": {
        const filterTitleResult = titleList.filter(
          (titleLabel) =>
            titleLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            titleLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setTitleListForm(filterTitleResult);
        break;
      }

      case "country": {
        const filterCountryResult = countryList.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setCountryListForm(filterCountryResult);
        break;
      }

      default: {
        break;
      }
    }
  };

  const handleFormError = (errors: IValidationAgencyInputs) => {
    const updatedInputs = structuredClone(inputs);

    Object.keys(errors).forEach((errorKey) => {
      if (errorKey in updatedInputs.address) {
        const value = String(errors[errorKey as keyof IValidationAgencyInputs]);

        updatedInputs.address[
          errorKey as keyof Pick<IAgencyInputs, "address">
        ].errorMessage = value;
      } else if (errorKey in updatedInputs) {
        const value = String(errors[errorKey as keyof IValidationAgencyInputs]);

        updatedInputs[
          errorKey as keyof Omit<IAgencyInputs, "address" | "agent_address">
        ].errorMessage = value;
      }
    });

    setInputs(updatedInputs);
  };

  const getInputsEntries = (
    inputs: Record<string, any>,
    entries: string[][],
  ) => {
    Object.entries(inputs).forEach(([inputKey, input]) => {
      if (inputKey === "address" && "address" in inputs[inputKey]) {
        getInputsEntries(inputs[inputKey], entries);
      } else if (
        inputKey === "address" ||
        inputKey === "city" ||
        inputKey === "state" ||
        inputKey === "zip" ||
        inputKey === "country"
      ) {
        entries.push([inputKey, input.value.value]);
      } else {
        entries.push([inputKey, input.id ?? input.value]);
      }
    });

    return entries;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const entries = getInputsEntries(inputs, []);
    const data = Object.fromEntries(entries);

    validateAgencyRegistration({
      data,
      onSuccess: async (validData: IValidationAgencyInputs) =>
        await handleCreateAgency(validData),
      onError: (errors: IValidationAgencyInputs) => handleFormError(errors),
    });
  };

  // Effect hook to initialize form options on component mount.
  useEffect(init, []);

  // Rendering the form with various input components.
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>{t("travel agency registration")}</h1>

        <div className={styles.notes}>
          <div className={styles.note}>
            <p className={styles.noteTitle}>{t("please note")}</p>

            <p className={styles.noteDescription}>
              {t(
                "If you are a member of a Host Agency or Franchise and use the same IATA/CLIA number for booking purposes, Please click",
              )}{" "}
              <CustomLink to={AGENT_REGISTRATION} className={styles.link}>
                {t("here to complete your Travel Advisor Registration.")}
              </CustomLink>
            </p>
          </div>
        </div>

        <h2 className={styles.subtitle}>{t("agency details")}</h2>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.groupInputs}>
            <Input
              value={inputs.name.value}
              valueKey="name"
              label={t("agency or company name")}
              placeholder={t("Please tell us your Agency or Company Name")}
              name="name"
              errorMessage={inputs.name.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.address.address.value.value}
              valueKey="address"
              label={t("Address")}
              placeholder={t("Agency address")}
              onChange={({ value, valueKey }) =>
                handleAddressChange({ value, valueKey, targetKey: "address" })
              }
              errorMessage={inputs.address.address.errorMessage}
              name="address"
              isRequired
            />

            <Input
              value={inputs.address.city.value.value}
              valueKey="city"
              label={t("City")}
              placeholder={t("Agency city")}
              onChange={({ value, valueKey }) =>
                handleAddressChange({ value, valueKey, targetKey: "address" })
              }
              errorMessage={inputs.address.city.errorMessage}
              name="city"
              isRequired
            />

            <Input
              value={inputs.address.state.value.value}
              valueKey="state"
              label={t("State")}
              placeholder={t("Agency state")}
              onChange={({ value, valueKey }) =>
                handleAddressChange({ value, valueKey, targetKey: "address" })
              }
              errorMessage={inputs.address.state.errorMessage}
              name="state"
              isRequired
            />

            <Input
              value={inputs.address.zip.value.value}
              valueKey="zip"
              label={t("Zip")}
              placeholder={t("Type zipcode")}
              onChange={({ value, valueKey }) =>
                handleAddressChange({ value, valueKey, targetKey: "address" })
              }
              errorMessage={inputs.address.zip.errorMessage}
              name="zip"
              isRequired
            />

            <SearchInput
              value={inputs.address.country.value.value}
              valueKey="country"
              displayKey="label"
              results={countryListForm}
              label={t("country")}
              placeholder={t("Select a country")}
              errorMessage={inputs.address.country.errorMessage}
              isLoading={false}
              isMultiple={false}
              onChange={handleSearchInputChange}
              onChosenChange={({ value, valueKey }) =>
                handleAddressChange({
                  value: value.value,
                  valueKey,
                  targetKey: "address",
                })
              }
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.telephone.value}
              valueKey="telephone"
              label={t("agency or company phone number")}
              placeholder={t("Agency phone number")}
              name="telephone"
              errorMessage={inputs.telephone.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.website.value}
              valueKey="website"
              label={t("travel agency website")}
              placeholder={t("Add your website URL")}
              name="website"
              errorMessage={inputs.website.errorMessage}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.group}>
            <div className={styles.groupInputs}>
              <CustomSelect
                items={regulatoryOptions}
                value={inputs.regulatory_type.value}
                valueKey="regulatory_type"
                label={t("regulatory type")}
                placeholder={t("Please select one")}
                errorMessage={inputs.regulatory_type.errorMessage}
                onChange={handleInputChange}
                isRequired
              />

              <Input
                value={inputs.regulatory.value}
                valueKey="regulatory"
                label={t("regulatory")}
                placeholder={t("Add your regulatory")}
                name="regulatory"
                errorMessage={inputs.regulatory.errorMessage}
                onChange={handleInputChange}
                isRequired
              />
            </div>
          </div>

          <div className={styles.groupInputs}>
            <CustomSelect
              items={consortiaOptions}
              value={inputs.consortia.value}
              valueKey="consortia"
              label={t("consortia")}
              placeholder={t("Please select one")}
              errorMessage={inputs.consortia.errorMessage}
              onChange={handleInputChange}
              isRequired
            />

            <CustomSelect
              items={marketsOptions}
              value={inputs.market.value}
              valueKey="market"
              label={t("market")}
              placeholder={t("Please select one")}
              errorMessage={inputs.market.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("your details")}</h2>

              <p className={styles.text}>
                {t(
                  "You will be the primary contact for this Agency or Company",
                )}
              </p>
            </div>

            <div className={styles.groupInputs}>
              <SearchInput
                value={inputs.title.value}
                valueKey="title"
                displayKey="label"
                results={titleListForm}
                label={t("Title")}
                placeholder={t("Select a title")}
                errorMessage={inputs.title.errorMessage}
                isMultiple={false}
                onChange={handleSearchInputChange}
                onChosenChange={({ value, valueKey }) =>
                  handleInputChange({ value: value.value, valueKey })
                }
                isRequired
              />

              <Input
                value={inputs.firstName.value}
                valueKey="firstName"
                label={t("your first name")}
                placeholder={t("Please tell us your first name")}
                name="firstName"
                errorMessage={inputs.firstName.errorMessage}
                onChange={handleInputChange}
                isRequired
              />

              <Input
                value={inputs.surname.value}
                valueKey="surname"
                label={t("your surname")}
                placeholder={t("Please tell us your second name")}
                name="surname"
                errorMessage={inputs.surname.errorMessage}
                onChange={handleInputChange}
                isRequired
              />
            </div>
          </div>

          <div className={styles.groupInputs}>
            <Input
              value={inputs.agent_telephone.value}
              valueKey="agent_telephone"
              label={t("your phone number")}
              placeholder={t("Agency phone number")}
              name="agent_telephone"
              errorMessage={inputs.agent_telephone.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.groupInputs}>
            <CustomSelect
              items={[
                { value: "agent", label: "Agent" },
                { value: "owner", label: "Owner" },
              ]}
              value={inputs.position.value}
              valueKey="position"
              label={t("position")}
              placeholder={t("Please select one")}
              errorMessage={inputs.position.errorMessage}
              onChange={handleInputChange}
              isRequired
            />
          </div>

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("agent address")}</h2>

              <p className={styles.text}>
                {t(
                  "Is your address different to the Agency or Company information " +
                    "provided above?",
                )}
              </p>
            </div>

            <RadioGroup
              value={inputs.isSameAddress.value}
              valueKey="isSameAddress"
              options={ADDRESS_RADIO_GROUP}
              onChange={handleInputChange}
            />
          </div>

          {inputs.isSameAddress.value === "different" && (
            <div className={styles.groupInputs}>
              <Input
                value={inputs.agent_address.address.value.value}
                valueKey="address"
                label={t("Address")}
                placeholder={t("Agent address")}
                onChange={({ value, valueKey }) =>
                  handleAddressChange({
                    value,
                    valueKey,
                    targetKey: "agent_address",
                  })
                }
                errorMessage={inputs.agent_address.address.errorMessage}
                name="address"
                isRequired
              />

              <Input
                value={inputs.agent_address.city.value.value}
                valueKey="city"
                label={t("City")}
                placeholder={t("Agent city")}
                onChange={({ value, valueKey }) =>
                  handleAddressChange({
                    value,
                    valueKey,
                    targetKey: "agent_address",
                  })
                }
                errorMessage={inputs.agent_address.city.errorMessage}
                name="city"
                isRequired
              />

              <Input
                value={inputs.agent_address.state.value.value}
                valueKey="state"
                label={t("State")}
                placeholder={t("Agent state")}
                onChange={({ value, valueKey }) =>
                  handleAddressChange({
                    value,
                    valueKey,
                    targetKey: "agent_address",
                  })
                }
                errorMessage={inputs.agent_address.state.errorMessage}
                name="state"
                isRequired
              />

              <Input
                value={inputs.agent_address.zip.value.value}
                valueKey="zip"
                label={t("Zip")}
                placeholder={t("Type zipcode")}
                onChange={({ value, valueKey }) =>
                  handleAddressChange({
                    value,
                    valueKey,
                    targetKey: "agent_address",
                  })
                }
                errorMessage={inputs.agent_address.zip.errorMessage}
                name="zip"
                isRequired
              />

              <SearchInput
                value={inputs.agent_address.country.value.value}
                valueKey="country"
                displayKey="label"
                results={countryListForm}
                label={t("country")}
                placeholder={t("Select a country")}
                errorMessage={inputs.agent_address.country.errorMessage}
                isLoading={false}
                isMultiple={false}
                onChange={handleSearchInputChange}
                onChosenChange={({ value, valueKey }) =>
                  handleAddressChange({
                    value: value.value,
                    valueKey,
                    targetKey: "agent_address",
                  })
                }
                isRequired
              />
            </div>
          )}

          <div className={styles.group}>
            <div>
              <h2 className={styles.subtitle}>{t("account details")}</h2>

              <p className={styles.text}>
                {t("Create an account to access ")}
                {agency_name}
              </p>
            </div>

            <div className={styles.groupInputs}>
              <Input
                value={inputs.email.value}
                valueKey="email"
                label={t("your email address")}
                placeholder={t("Your email address")}
                name="email"
                errorMessage={inputs.email.errorMessage}
                onChange={handleInputChange}
                autoComplete="new-password"
                isRequired
              />
            </div>

            <div className={styles.groupInputs}>
              <Input
                value={inputs.b2b_password.value}
                valueKey="b2b_password"
                label={t("password")}
                placeholder={t("Enter a password")}
                name="b2b_password"
                errorMessage={inputs.b2b_password.errorMessage}
                onChange={handleInputChange}
                autoComplete="new-password"
                isRequired
                secured
              />

              <Input
                value={inputs.confirm_b2b_password.value}
                valueKey="confirm_b2b_password"
                label={t("confirm password")}
                placeholder={t("Enter a password confirmation")}
                name="confirm_b2b_password"
                errorMessage={inputs.confirm_b2b_password.errorMessage}
                onChange={handleInputChange}
                autoComplete="new-password"
                isRequired
                secured
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              label={t("complete registration")}
              type="submit"
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

// Export the AgencyRegistration component for use elsewhere in the application.
export default AgencyRegistration;
