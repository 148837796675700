import classNames from "classnames";
import { useEffect, useRef, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useClickOutside from "../../../hooks/useClickOutside";
import useCurrentRoutes from "../../../hooks/useCurrentRoutes";
import { destroySession } from "../../../store/actions/sessionActions";
import { showToast } from "../../../store/slices/toastSlice";
import { useTypedDispatch } from "../../../store/store";
import { ROOT } from "../../../utils/constants/routes";
import TOAST from "../../../utils/constants/toast";
import CustomNavLink from "../../shared/CustomNavLink";

import styles from "./index.module.scss";

// TypeScript interface to specify the props structure expected by the SideMenu component.
interface ISideMenuProps {
  isVisible: boolean; // Controls the visibility of the side menu.
  onClose: () => void; // Function to call when the menu needs to be closed.
}

// Definition of the SideMenu functional component.
function SideMenu({ isVisible, onClose }: ISideMenuProps) {
  // Hook for dispatching actions to the Redux store.
  const dispatch = useTypedDispatch();
  // Hook for programmatically navigating between routes.
  const navigate = useNavigate();

  // Hook to access the current URL location.
  const { pathname } = useLocation();
  // Custom hook to get the current routes configuration for dynamic navigation rendering.
  const { sideMenu } = useCurrentRoutes();

  // Ref hook to reference the menu DOM element for click outside detection.
  const menuRef = useRef(null);

  // Function to handle click events on menu items, with special handling for logout.
  const handleLinkClick = (routeKey: string) => () => {
    switch (routeKey) {
      case "LOGOUT": {
        // Calls onClose to close the menu.
        onClose();

        // Schedules the logout action and navigation to the root path after the current execution context.
        requestAnimationFrame(() => {
          dispatch(destroySession());

          dispatch(
            showToast({
              type: TOAST.SUCCESS_TYPE,
              message: "Signed out",
              duration: TOAST.DEFAULT_DURATION,
            }),
          );

          navigate(ROOT);
        });
        break;
      }

      default: {
        // No default action.
        break;
      }
    }
  };

  // Effect hook to close the menu when navigating to a different path.
  useEffect(onClose, [pathname]);

  // Custom hook to call onClose when a click outside of the menuRef element is detected.
  useClickOutside(menuRef, onClose);

  // Rendering the side menu with conditional visibility based on the isVisible prop.
  return (
    <div
      ref={menuRef} // Assigning the ref to the menu container for click outside detection.
      className={classNames(styles.container, {
        [styles.container_visible]: isVisible, // Conditionally applying a class to make the menu visible.
      })}>
      <div className={classNames(styles.content)}>
        <nav className={styles.nav}>
          {/* Dynamically rendering menu items based on the sideMenu configuration. */}
          {Object.entries(sideMenu).map(([routeKey, route]) => {
            const { type, label } = route;

            if (type === "button") {
              // Renders a button for actions like logout.
              return (
                <span
                  key={routeKey}
                  className={styles.buttonLink}
                  onClick={handleLinkClick(routeKey)}>
                  {label()}
                </span>
              );
            }

            if (type === "link") {
              // Renders a navigational link for standard navigation.
              return (
                <CustomNavLink
                  key={routeKey}
                  label={label()}
                  to={routeKey}
                  className={styles.link}
                />
              );
            }

            // Fallback to render nothing for unsupported types.
            return <Fragment key={routeKey} />;
          })}
        </nav>
      </div>

      {/* Renders a backdrop behind the menu, which closes the menu when clicked. */}
      <div
        onClick={onClose} // Click handler to close the menu.
        className={classNames(styles.backdrop, {
          [styles.backdrop_visible]: isVisible, // Conditionally applying a class to make the backdrop visible.
        })}
      />
    </div>
  );
}

// Exporting the SideMenu component for use in other parts of the application.
export default SideMenu;
