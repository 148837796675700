import { useMemo } from "react"; // Importing useMemo hook from React library

import { useTypedSelector } from "../store/store"; // Importing custom hook useTypedSelector from "../store/store"
import routes from "../utils/constants/router"; // Importing routes object from "../utils/constants/router"
import { IRouteElement, ISideMenuRoute } from "../utils/constants/routes"; // Importing types IRouteElement and ISideMenuRoute from "../utils/constants/routes"

function useCurrentRoutes() {
  // Defining a custom hook named useCurrentRoutes
  const session = useTypedSelector((state) => state.session); // Retrieving session state using useTypedSelector custom hook

  const navigationRoutes: {
    // Declaring navigationRoutes object
    routes: IRouteElement; // Property for storing route elements
    sideMenu: ISideMenuRoute; // Property for storing side menu routes
  } = useMemo(() => {
    // Memoizing the computation to prevent unnecessary recalculations
    const isPrivate = !!session.isInitialized && !!session.sessionKey; // Checking if the session is private

    if (isPrivate) {
      // If session is private
      return { routes: routes.private, sideMenu: routes.privateSideMenu }; // Return private routes and side menu
    }

    return { routes: routes.public, sideMenu: routes.publicSideMenu }; // Otherwise, return public routes and side menu
  }, [session.isInitialized, session.sessionKey]); // Dependency array for useMemo to track changes

  return navigationRoutes; // Returning the navigationRoutes object containing current routes
}

export default useCurrentRoutes; // Exporting the custom hook useCurrentRoutes
