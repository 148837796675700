import classNames from "classnames";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SEARCH } from "../../../utils/constants/routes";
import CustomLink from "../../shared/CustomLink";

import styles from "./index.module.scss";

// TypeScript interface to define the shape of local storage data related to search.
interface ISearchLocalStorage {
  duration: string | null;
  date: string[];
  chosenLocations: [] | Array<Record<string, string>>;
}

// Defines the main component that represents the search bar.
function SearchBar() {
  // Hook to use the internationalization functionality.
  const { t } = useTranslation();

  // State for storing and setting search data retrieved from local storage.
  const [data, setData] = useState<ISearchLocalStorage>({
    duration: "",
    date: [],
    chosenLocations: [],
  });

  // Effect hook to load search data from local storage on component mount.
  useEffect(() => {
    // Guard clause to check if there is any 'search' data in local storage.
    if (!localStorage.getItem("search")) {
      return;
    }

    // Parsing the stored search string back into an object.
    const search: ISearchLocalStorage = JSON.parse(
      localStorage.getItem("search") ?? "",
    );

    // Updating the component state with the retrieved search data.
    setData(search);
  }, []); // Empty dependency array means this effect runs once on component mount.

  // Render function for the component.
  return (
    // Container div with applied CSS classes.
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.content)}>
        {/* Invoking a sub-component to format the search string for display. */}
        <span>
          <FormatSearchString {...data} />
        </span>
        {/* Link to edit the search criteria, using a CustomLink component. */}
        <CustomLink
          className={styles.searchEditLink}
          to={{ pathname: SEARCH, search: "edit" }}>
          <span>{t("edit search")}</span>
        </CustomLink>
      </div>
    </div>
  );
}

// Sub-component to format and display the search criteria.
function FormatSearchString({
  chosenLocations,
  date,
  duration,
}: ISearchLocalStorage) {
  const { t } = useTranslation();

  // Formatting dates to be human-readable, deduplicating month names.
  let datesFormat = date
    ?.filter((date) => date)
    .map((date) => dayjs(date).format("MMMM"));

  datesFormat = [...new Set(datesFormat)];

  // Render function for displaying formatted search criteria.
  return (
    <p>
      {/* Prefix indicating a search was made. */}
      {t("You searched ")}{" "}
      {/* Mapping chosen locations to JSX elements, joined by commas. */}
      {chosenLocations?.map((location, index) => (
        <strong key={index}>
          {!!index && " , "}
          {location.name}
        </strong>
      ))}
      {/* Interjecting 'in' before listing the months of the search. */}
      {t(" in ")}
      {/* Mapping formatted dates to JSX elements, separated by dashes for range. */}
      {datesFormat?.map((date, index) => (
        <strong key={index}>
          {!!index && " - "}
          {date}
        </strong>
      ))}
      {/* Conditionally appending the duration of the search, if present. */}
      {duration && (
        <span>
          {" "}
          {t("for")}{" "}
          <strong>
            {duration} {t("nights")}
          </strong>
        </span>
      )}
    </p>
  );
}

// Exporting the SearchBar component for use in other parts of the application.
export default SearchBar;
