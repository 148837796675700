import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReservationListInterface } from "../../../../../store/slices/reservationSlice";
import { useTypedSelector } from "../../../../../store/store";
import { BOOKING, routeParams } from "../../../../../utils/constants/routes";
import SvgIcon from "../../../../shared/SvgIcon";
import styles from "../../index.module.scss";

// Defining props interface for the Content component.
interface ContentProps {
  bookingList: ReservationListInterface[];
  sortBookingList: (type: string) => void;
}

// The Content component, displaying a list of bookings.
function Content({ bookingList, sortBookingList }: ContentProps) {
  const { t } = useTranslation();

  const { date_format, pnr_column_name, cruise_column_name } = useTypedSelector(
    (state) => state.environment,
  );

  // Returning the JSX of the Content component.
  return (
    // Container for the fields.
    <div className={styles.fieldsContainer}>
      {/* Header row of the table. */}
      <div className={styles.headerRow}>
        {/* Booking date column with sorting action. */}
        <div
          className={classNames(styles.column, styles.column_md)}
          style={{ cursor: "pointer" }}
          onClick={() => sortBookingList("booking_date")}>
          <span className={styles.uppercase}>{t("booked date")}</span>
          <div className={styles.svgBlock}>
            {/* Icons indicating sorting direction. */}
            <SvgIcon className={styles.arrowUp} type="FiTriangle" />
            <SvgIcon className={styles.arrowDown} type="FiTriangle" />
          </div>
        </div>

        {/* PNR column with no sorting action. */}
        <div className={classNames(styles.column, styles.column_sm)}>
          <span className={styles.uppercase}>{pnr_column_name}</span>
        </div>
        {/* Passenger name column with sorting action. */}
        <div
          className={classNames(styles.column, styles.column_lg)}
          style={{ cursor: "pointer" }}
          onClick={() => sortBookingList("passenger_name")}>
          <span className={styles.uppercase}>{t("passenger name")}</span>
          <div className={styles.svgBlock}>
            {/* Icons indicating sorting direction. */}
            <SvgIcon className={styles.arrowUp} type="FiTriangle" />
            <SvgIcon className={styles.arrowDown} type="FiTriangle" />
          </div>
        </div>
        {/* Embarkation date column with sorting action. */}
        <div
          className={classNames(styles.column, styles.column_md)}
          style={{ cursor: "pointer" }}
          onClick={() => sortBookingList("embarkation_date")}>
          <span className={styles.uppercase}>{t("embark date")}</span>
          <div className={styles.svgBlock}>
            {/* Icons indicating sorting direction. */}
            <SvgIcon className={styles.arrowUp} type="FiTriangle" />
            <SvgIcon className={styles.arrowDown} type="FiTriangle" />
          </div>
        </div>
        {/* Cruise name column with no sorting action. */}
        <div className={classNames(styles.column, styles.column_lg)}>
          <span className={styles.uppercase}>{cruise_column_name}</span>
        </div>
        {/* Empty column for action icons. */}
        <div className={styles.actionColumn} />
      </div>
      {/* Mapping over the bookingList to display each booking. */}
      {bookingList.map((booking) => {
        return (
          <div key={nanoid()} className={styles.contentRow}>
            {/* Columns for booking details. */}
            <div className={classNames(styles.column, styles.column_md)}>
              <span>{dayjs(booking.created).format(date_format)}</span>
            </div>

            <div className={classNames(styles.column, styles.column_sm)}>
              <span>{booking.pnr}</span>
            </div>

            <div className={classNames(styles.column, styles.column_lg)}>
              <span>{booking.lead_lastname}</span>
            </div>

            <div className={classNames(styles.column, styles.column_md)}>
              <span>{dayjs(booking.embarkation_date).format(date_format)}</span>
            </div>

            <div className={classNames(styles.column, styles.column_lg)}>
              <span>{booking.cruise_name}</span>
            </div>

            {/* Action column with a link to the booking detail page. */}
            <div className={styles.actionColumn}>
              <Link to={routeParams(BOOKING, [["pnr", booking.pnr]])}>
                <SvgIcon className={styles.svgAction} type="FiExternalLink" />
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
}

// Exporting the Content component as default.
export default Content;
