// Import necessary modules and components
import dayjs from "dayjs"; // Library for date manipulation
import { useEffect, useMemo, useState } from "react"; // React hooks for managing component state and lifecycle
import { useTranslation } from "react-i18next"; // Translation hook for multilingual support
import { useLocation, useNavigate } from "react-router-dom"; // React Router hooks for navigation and accessing URL parameters

import { clearGuest } from "../../../store/slices/guestsSlice"; // Redux action to clear guest data
import { showModal } from "../../../store/slices/modalSlice"; // Redux action to show a modal
import { useTypedDispatch, useTypedSelector } from "../../../store/store"; // Redux hooks for typed dispatch and selector
import MODAL from "../../../utils/constants/modal"; // Constants for modal types
import { currencyToFormat } from "../../../utils/helpers/currency"; // Function to format currency
import { insertValuesToString } from "../../../utils/helpers/insertValuesToString";
import { validateRooms } from "../../../utils/validation"; // Function for form validation
import LoadingContainer from "../../containers/LoadingContainer"; // Container component for loading state
import Button from "../../shared/Button"; // Custom Button component
import CustomCarousel from "../../shared/Carousel"; // Custom Carousel component for image slideshow
import Counter from "../../shared/Counter"; // Custom Counter component for numeric input

import styles from "./index.module.scss"; // Importing SCSS module for styling.

// Define types for input change handler, validation inputs, and input state
type InputChangeHandler = Record<string, string>;

interface IValidationInputs {
  rooms: string;
}

interface IInputs {
  rooms: { value: string; errorMessage: string };
}

interface Market {
  price: string;
  gft: string;
}

// Component for displaying when the cruise data is not found
function DummyCruise() {
  const { t } = useTranslation();

  return <p>{t("Cruise not found")}</p>;
}

// Main Cruise component
function Cruise() {
  // Define hooks for managing component state and accessing router-related data
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Define Redux state selectors
  const { pathname, search } = useLocation();

  const { cruise, isCruiseLoading } = useTypedSelector((state) => state.search);

  const {
    date_format,
    subtract_gft,
    number_stateroom_page_description,
    gft_additional_text,
  } = useTypedSelector((state) => state.environment);

  // Define state variables for input values and errors
  const [inputs, setInputs] = useState<IInputs>({
    rooms: { value: "1", errorMessage: "" },
  });

  // Calculate price based on cruise data and selected rooms
  const price = useMemo(() => {
    if (!cruise) {
      return { price: 0, gft: 0, currency: "USD" };
    }

    const prices = Object.values(cruise.markets)
      .filter((market) => typeof market === "object" && market !== null)
      .map((market) => {
        const typcedMarket = market as Market;
        const marketPrice = parseFloat(typcedMarket.price);
        const marketGft = parseFloat(typcedMarket.gft);
        const adjustedPrice = subtract_gft
          ? marketPrice - marketGft
          : marketPrice;

        return { price: adjustedPrice, gft: marketGft, currency: "USD" };
      })
      .filter((market) => !isNaN(market.price) && market.price !== 0);

    if (prices.length === 0) {
      return { price: 0, gft: 0, currency: "USD" };
    }

    const minPriceData = prices.reduce((min, current) => {
      return current.price < min.price ? current : min;
    });

    return minPriceData;
  }, [cruise, subtract_gft]);

  const additionalGftText = useMemo(
    () =>
      insertValuesToString(gft_additional_text, {
        gft: currencyToFormat(price.gft, price.currency ?? "USD"),
      }),
    [price],
  );

  // Function to show itinerary modal
  const handleShowModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  // Function to handle input change
  const handleInputChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));
  };

  // Function to handle form errors
  const handleFormError = (errors: IValidationInputs) => {
    const updatedInputs = structuredClone(inputs);

    Object.keys(errors).forEach((errorKey) => {
      if (updatedInputs[errorKey as keyof IInputs]) {
        updatedInputs[errorKey as keyof IInputs].errorMessage =
          errors[errorKey as keyof IValidationInputs];
      }
    });

    setInputs(updatedInputs);
  };

  // Function to handle form submission
  const handleSubmit = () => {
    validateRooms({
      data: {
        rooms: inputs.rooms.value,
      },
      onSuccess: async (validData: IValidationInputs) => {
        navigate(`${pathname}/rooms/${search}&rooms=${validData.rooms}&room=1`);
        // eslint-disable-next-line no-restricted-globals
        scrollTo(0, 0);
      },
      onError: (errors: IValidationInputs) => handleFormError(errors),
    });
  };

  // If data is not found displaying component with no data
  if (!isCruiseLoading && !cruise) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <DummyCruise />
        </div>
      </div>
    );
  }

  // Effect to scroll to top and clear guest data on component mount
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(clearGuest());
  }, []);

  // Render DummyCruise component if cruise data is not found
  if (!isCruiseLoading && !cruise) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <DummyCruise />
        </div>
      </div>
    );
  }

  // Return the main component structure
  return (
    <LoadingContainer isLoading={isCruiseLoading}>
      <div className={styles.container}>
        <div className={styles.content}>
          {/* Display images, ship details, price, stateroom selection */}
          <div className={styles.imagesContainer}>
            <CustomCarousel
              items={cruise?.ship.images ?? []}
              className={styles.carousel}
            />

            <Button
              className={styles.itineraryButton}
              label={t("view itinerary")}
              variant="secondary"
              icon="FiPlus"
              onClick={handleShowModal}
            />

            <p className={styles.name}>{cruise?.cruise.name ?? ""}</p>
          </div>

          {/* Display ship details */}
          <table className={styles.details}>
            <tbody>
              <tr>
                <td>{t("SHIP NAME")}</td>
                <td>{cruise?.ship.name}</td>
              </tr>

              <tr>
                <td>{t("DEPARTS")}</td>
                <td>{dayjs(cruise?.embark ?? "").format(date_format)}</td>
              </tr>
            </tbody>
          </table>

          {/* Display price and stateroom selection */}
          <div className={styles.price}>
            <span className={styles.price_from}>{t("From")}</span>

            <span className={styles.price_value}>
              {currencyToFormat(price.price, cruise?.markets.currency ?? "USD")}
              &nbsp;pp
            </span>
          </div>

          <div className={styles.staterooms}>
            <p className={styles.staterooms_title}>
              {t("How many staterooms do you need?")}
            </p>

            <span className={styles.staterooms_subtitle}>
              {number_stateroom_page_description || ""}
            </span>

            {/* Custom components for choosing amount of rooms */}
            <Counter
              value={inputs.rooms.value} // Current value from min to max
              valueKey="rooms"
              errorMessage={inputs.rooms.errorMessage}
              min={1} // Min return value
              max={3} // Max return value
              onChange={handleInputChange}
            />
          </div>

          {/* Continue button */}
          <Button
            label={t("continue to staterooms")}
            className={styles.submitButton}
            onClick={handleSubmit}
          />

          {gft_additional_text && (
            <span className={styles.footerNote}>{additionalGftText}</span>
          )}
        </div>
      </div>
    </LoadingContainer>
  );
}

// Exporting the Cruise component as default.
export default Cruise;
