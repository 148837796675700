// Importing necessary dependencies from React and ReactDOM for rendering components, Provider for Redux store integration, and PersistGate for Redux persist integration.
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// Importing the Redux store and persistor from our custom store configuration.
import App from "./App";
import { store, persistor } from "./store/store";

// Importing the main App component.

// Importing internationalization setup.
import "./utils/i18n/index";

// Importing global styles.
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";

// Creating a root for rendering React components.
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// Rendering the main application wrapped with Redux Provider and PersistGate for state management and persistence.
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
