import {
  AirwallexEnv,
  CardNumberElementType,
  confirmPaymentIntent,
  CvcElementType,
  ExpiryDateElementType,
  getElement,
  Intent,
  loadAirwallex,
} from "airwallex-payment-elements";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useLazyCreateAirwallexIntentQuery } from "../../../../../store/services/PaymentService";
import { useLazyConfirmReservationQuery } from "../../../../../store/services/ReservationService";
import { showModal } from "../../../../../store/slices/modalSlice";
import { showToast } from "../../../../../store/slices/toastSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../../store/store";
import MODAL from "../../../../../utils/constants/modal";
import TOAST from "../../../../../utils/constants/toast";
import BookingDetails from "../../../../base/BookingDetails";
import LoadingContainer from "../../../../containers/LoadingContainer";
import Alert from "../../../../shared/Alert";
import Button from "../../../../shared/Button";

import LegalData from "./../../molecules/LegalData";
import PriceType from "./../../molecules/PriceType";
import { usePaymentState } from "./hooks/usePaymentState";
import styles from "./index.module.scss";

interface PaymentConfirmation extends Intent {
  authorization_code: string;
  payment_method_transaction_id: string;
  latest_payment_attempt: {
    authorization_code: string;
    payment_method_transaction_id: string;
    id: string;
    payment_intent_id: string;
    payment_method: {
      card: {
        brand: string;
        last4: string;
      };
    };
  };
}

function Payment() {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const [createIntent] = useLazyCreateAirwallexIntentQuery();
  const [confirmReservation] = useLazyConfirmReservationQuery();
  const [searchParams] = useSearchParams();

  const { search } = useLocation();
  const { cruiseId } = useParams();

  const { isCruiseLoading } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);

  const {
    payment_checkbox_1,
    payment_checkbox_2,
    payment_checkbox_3,
    payment_checkbox_4,
    payment_checkbox_5,
    new_booking_payment_info,
    airwallex_env,
  } = useTypedSelector((state) => state.environment);

  const source = searchParams.get("source") ?? "";

  const {
    priceType,
    setPriceType,
    isLoading,
    setIsLoading,
    cardNumberReady,
    setCardNumberReady,
    cvcReady,
    setCvcReady,
    expiryReady,
    setExpiryReady,
    cardNumberComplete,
    setCardNumberComplete,
    cvcComplete,
    setCvcComplete,
    expiryComplete,
    setExpiryComplete,
    errorMessage,
    setErrorMessage,
    inputErrorMessage,
    setInputErrorMessage,
  } = usePaymentState();

  const cardNumberRef = useRef<CardNumberElementType | null>(null);
  const expiryRef = useRef<ExpiryDateElementType | null>(null);
  const cvcRef = useRef<CvcElementType | null>(null);

  const [legalData, setLegalData] = useState({
    checkbox_1: !payment_checkbox_1,
    checkbox_2: !payment_checkbox_2,
    checkbox_3: !payment_checkbox_3,
    checkbox_4: !payment_checkbox_4,
    checkbox_5: !payment_checkbox_5,
  });

  const transactions: [
    {
      full?: number;
      deposit?: number;
      amount: number;
      currency: string;
      made_by: string;
      transaction_type: string;
    },
  ] = useMemo(() => {
    const output = {
      full: 0,
      deposit: 0,
      amount: 0,
      currency: rooms?.[1].pricing?.payment_schedule?.[0].currency ?? "USD",
      made_by: "Website Authorize.net",
      transaction_type: "creditcard",
    };

    const deposit = Object.values(rooms ?? {}).reduce((sum, room) => {
      const roomDeposit = room?.pricing?.payment_schedule?.[0]?.amount ?? 0;

      return Number(sum) + Number(roomDeposit);
    }, 0);

    output.deposit = deposit;

    for (const key in rooms) {
      if (Object.prototype.hasOwnProperty.call(rooms, key)) {
        const paymentSchedule = rooms[+key].pricing?.payment_schedule;

        if (Array.isArray(paymentSchedule)) {
          paymentSchedule.forEach((payment) => {
            if (payment.amount) {
              output.full += parseFloat(payment.amount);
            }
          });
        }
      }
    }

    if (priceType === "full") {
      output.amount = output.full;
    }

    if (priceType === "deposit") {
      output.amount = output.deposit;
    }

    output.amount = Math.ceil(output.amount * 100) / 100;
    output.full = Math.ceil(output.full * 100) / 100;
    output.deposit = Math.ceil(output.deposit * 100) / 100;

    return [output];
  }, [rooms, cardNumberRef.current, priceType]);

  const handleShowItineraryModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  const handlePriceTypeChange = (type: string) =>
    setPriceType(type as "full" | "deposit");

  const handleLegalDataChange = ({
    value,
    valueKey,
  }: {
    value: boolean;
    valueKey: string;
  }) => {
    setLegalData((prev) => ({ ...prev, [valueKey]: value }));
  };

  const onPaymentFail = () => {
    dispatch(
      showToast({
        type: TOAST.ERROR_TYPE,
        message: t("Payment failed"),
        duration: TOAST.DEFAULT_DURATION,
      }),
    );
  };

  const onPaymentSuccess = () => {
    dispatch(
      showToast({
        type: TOAST.SUCCESS_TYPE,
        message: t("Payment success"),
        duration: TOAST.DEFAULT_DURATION,
      }),
    );

    navigate(`/search-results/${cruiseId}/payment-confirmation${search}`);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    setErrorMessage("");

    const cardNumElement = getElement("cardNumber");

    if (!cardNumElement) {
      setIsLoading(false);

      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: t("Card number element is not available"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      return;
    }

    try {
      const { amount, currency } = transactions[0];

      const { data } = await createIntent({
        pnr: rooms?.[1]?.pnr ?? "",
        transactions: [{ amount, currency }],
        source,
      });

      const paymentConfirmation = (await confirmPaymentIntent({
        element: cardNumElement,
        id: data?.id,
        client_secret: data?.client_secret,
        payment_method_options: {
          card: {
            auto_capture: true,
          },
        },
      })) as PaymentConfirmation;

      console.log({
        element: cardNumElement,
        id: data?.id,
        client_secret: data?.client_secret,
        payment_method_options: {
          card: {
            auto_capture: true,
          },
        },
      });

      const intentTransactions = [
        {
          transaction_type: "creditcard" as const,
          made_on: paymentConfirmation.created_at,
          made_by: "Website" as const,
          amount: paymentConfirmation.amount,
          currency: paymentConfirmation.currency,
          card_clearance: {
            clearance_system: "airwallex" as const,
            auth_code:
              paymentConfirmation.latest_payment_attempt.authorization_code,
            transaction_id:
              paymentConfirmation.latest_payment_attempt
                .payment_method_transaction_id,
            other: {
              card_type:
                paymentConfirmation.latest_payment_attempt.payment_method.card
                  .brand,
              last_four:
                paymentConfirmation.latest_payment_attempt.payment_method.card
                  .last4,
              transaction_id: paymentConfirmation.latest_payment_attempt.id,
              intent_id:
                paymentConfirmation.latest_payment_attempt.payment_intent_id,
            },
          },
        },
      ];

      await confirmReservation({
        pnr: rooms?.[1]?.pnr ?? "",
        transactions: intentTransactions,
      });

      setIsLoading(false);
      onPaymentSuccess();
    } catch (error) {
      const errorText =
        error instanceof Error ? error.message : JSON.stringify(error);

      setIsLoading(false);

      onPaymentFail();
      setErrorMessage(errorText);
    }
  };

  useEffect(() => {
    const initializeAirwallex = async () => {
      try {
        const airwallex = await loadAirwallex({
          env: airwallex_env as AirwallexEnv,
          origin: window.location.origin,
        });

        const cardNumEle = airwallex?.createElement("cardNumber");
        const cvcEle = airwallex?.createElement("cvc");
        const expiryEle = airwallex?.createElement("expiry");

        if (!cardNumEle || !cvcEle || !expiryEle) {
          return;
        }

        cardNumEle?.mount("cardNumber");
        cvcEle?.mount("cvc");
        expiryEle?.mount("expiry");
        cardNumberRef.current = cardNumEle;
        expiryRef.current = expiryEle;
        cvcRef.current = cvcEle;
      } catch (error) {
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: t("Error initializing Airwallex"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      }
    };

    initializeAirwallex();

    const onReady = (event: { detail: { type: string } }) => {
      const { type } = event.detail;

      if (type === "cardNumber") {
        setCardNumberReady(true);
      }

      if (type === "cvc") {
        setCvcReady(true);
      }

      if (type === "expiry") {
        setExpiryReady(true);
      }
    };

    const onChange = (event: {
      detail: { type: string; complete: boolean };
    }) => {
      const { type, complete } = event.detail;

      if (type === "cardNumber") {
        setCardNumberComplete(complete);
      }

      if (type === "cvc") {
        setCvcComplete(complete);
      }

      if (type === "expiry") {
        setExpiryComplete(complete);
      }
    };

    const onFocus = (event: CustomEvent) => {
      const { type } = event.detail;

      setInputErrorMessage({
        ...inputErrorMessage,
        [type]: "",
      });
    };

    const onBlur = (event: CustomEvent) => {
      const { type, error } = event.detail;

      setInputErrorMessage((prev) => ({
        ...prev,
        [type]: error?.message ?? JSON.stringify(error),
      }));
    };

    const cardNumberElement = document.getElementById("cardNumber");
    const expiryElement = document.getElementById("expiry");
    const cvcElement = document.getElementById("cvc");
    const domElementArray = [cardNumberElement, expiryElement, cvcElement];

    domElementArray.forEach((element) => {
      element?.addEventListener("onReady", onReady as unknown as EventListener);
      element?.addEventListener(
        "onChange",
        onChange as unknown as EventListener,
      );
      element?.addEventListener("onFocus", onFocus as unknown as EventListener);
      element?.addEventListener("onBlur", onBlur as unknown as EventListener);
    });

    return () => {
      domElementArray.forEach((element) => {
        element?.removeEventListener(
          "onReady",
          onReady as unknown as EventListener,
        );
        element?.removeEventListener(
          "onChange",
          onChange as unknown as EventListener,
        );
        element?.removeEventListener(
          "onFocus",
          onFocus as unknown as EventListener,
        );
        element?.removeEventListener(
          "onBlur",
          onBlur as unknown as EventListener,
        );
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const allElementsReady = cardNumberReady && cvcReady && expiryReady;
  const allElementsComplete =
    cardNumberComplete && cvcComplete && expiryComplete;

  const legalDataAccepted =
    legalData.checkbox_1 &&
    legalData.checkbox_2 &&
    legalData.checkbox_3 &&
    legalData.checkbox_4 &&
    legalData.checkbox_5;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <h1 className={styles.title}>{t("Payment")}</h1>

          {Boolean(new_booking_payment_info) && (
            <Alert message={new_booking_payment_info} />
          )}

          <LoadingContainer isLoading={isCruiseLoading}>
            <PriceType
              full={transactions[0].full ?? 0}
              deposit={transactions[0].deposit ?? 0}
              currency={transactions[0].currency || "USD"}
              onPriceTypeChange={handlePriceTypeChange}
            />
          </LoadingContainer>

          <LoadingContainer isLoading={!allElementsReady} />

          <form
            className={styles.form}
            style={{ display: !allElementsReady ? "none" : "flex" }}>
            {errorMessage.length > 0 && (
              <div className={styles.inputError}>{errorMessage}</div>
            )}
            <div style={{ display: allElementsReady ? "block" : "none" }}>
              <div className={styles.fieldContainer}>
                <div className={styles.labelAirWallex}>Card number</div>

                <div className={styles.inputAirWallex} id="cardNumber" />

                <div className={styles.inputError}>
                  {inputErrorMessage.cardNumber || " "}
                </div>
              </div>

              <div className={styles.fieldContainer}>
                <div className={styles.labelAirWallex}>Expiry</div>

                <div className={styles.inputAirWallex} id="expiry" />

                <span className={styles.inputError}>
                  {inputErrorMessage.expiry || " "}
                </span>
              </div>

              <div className={styles.fieldContainer}>
                <div className={styles.labelAirWallex}>Card Code</div>

                <div className={styles.inputAirWallex} id="cvc" />

                <span className={styles.inputError}>
                  {inputErrorMessage.cvc || " "}
                </span>
              </div>
            </div>

            <LegalData
              checkbox_1_checked={legalData.checkbox_1}
              checkbox_2_checked={legalData.checkbox_2}
              checkbox_3_checked={legalData.checkbox_3}
              checkbox_4_checked={legalData.checkbox_4}
              checkbox_5_checked={legalData.checkbox_5}
              onChange={handleLegalDataChange}
            />
          </form>

          {Boolean(new_booking_payment_info) && (
            <Alert message={new_booking_payment_info} />
          )}

          <div className={styles.inputs}>
            <Button
              label={isLoading ? t("Loading") : t("Pay")}
              className={styles.button}
              onClick={handleConfirm}
              loading={isLoading}
              disabled={!allElementsComplete || isLoading || !legalDataAccepted}
            />
          </div>
        </div>

        <div className={styles.right}>
          <Button
            label="view itinerary"
            variant="secondary"
            icon="FiPlus"
            onClick={handleShowItineraryModal}
          />

          <BookingDetails />
        </div>
      </div>
    </div>
  );
}

export default Payment;
