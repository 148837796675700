import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import Carousel from "react-bootstrap/Carousel";

import styles from "./index.module.scss";
import "./index.css";

type IImageItem = string;

interface ICarouselProps {
  items: IImageItem[];
  className?: string;
  slideClassName?: string;
}

function CustomCarousel({ items, className, slideClassName }: ICarouselProps) {
  return (
    <Carousel
      className={classNames(styles.container, className)}
      indicators={false}
      interval={null}
      prevIcon={
        <div className={styles.arrowContainer}>
          <span className="carousel-control-prev-icon" />
        </div>
      }
      nextIcon={
        <div className={styles.arrowContainer}>
          <span className="carousel-control-next-icon" />
        </div>
      }>
      {items.filter(Boolean).map((slide) => {
        return (
          <Carousel.Item key={nanoid()}>
            <img
              src={slide}
              className={classNames(styles.slide, slideClassName)}
              alt="carousel slide"
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

// Default props for the CustomCarousel component
CustomCarousel.defaultProps = {
  className: "",
};

// Exporting the CustomCarousel component
export default CustomCarousel;
