import AgencyRegistration from "../../components/pages/AgencyRegistration";
import AgentHome from "../../components/pages/AgentHome";
import AgentRegistration from "../../components/pages/AgentRegistration";
import Booking from "../../components/pages/Booking";
import CabinSelect from "../../components/pages/CabinSelect";
import Cruise from "../../components/pages/Cruise";
import CurrentBookings from "../../components/pages/CurrentBookings";
import ForgottenPassword from "../../components/pages/ForgottenPassword";
import Passengers from "../../components/pages/Passengers";
import AirWallexPayment from "../../components/pages/Payment/payment-providers/airwallex";
import AuthorizePayment from "../../components/pages/Payment/payment-providers/authorize";
import PaymentConfirmation from "../../components/pages/PaymentConfirmation";
import PreviousBookings from "../../components/pages/PreviousBookings";
import ResetPassword from "../../components/pages/ResetPassword";
import Rooms from "../../components/pages/Rooms";
import Search from "../../components/pages/Search";
import SearchResults from "../../components/pages/SearchResults";
import SignIn from "../../components/pages/SignIn";
import { store } from "../../store/store";
import i18n from "../i18n";

import {
  IRouteElement,
  ISideMenuRoute,
  CABINS_SELECT,
  CRUISE,
  PASSENGERS,
  ROOMS,
  ROOT,
  SEARCH_RESULTS,
  SIGN_IN,
  FORGOTTEN_PASSWORD,
  RESET_PASSWORD,
  LOGOUT,
  SEARCH,
  AGENCY_REGISTRATION,
  AGENT_REGISTRATION,
  PAYMENT,
  CURRENT_BOOKINGS,
  BOOKING,
  PREVIOUS_BOOKINGS,
  PAYMENT_CONFIRMATION,
  PaymentProvider,
} from "./routes";

const { t } = i18n;

const PAYMENT_PROVIDER = process.env.REACT_APP_CABIN_SELECT_PAYMENT_PROVIDER as
  | PaymentProvider
  | undefined;

const PUBLIC_ROUTES: IRouteElement = {
  [ROOT]: { element: SignIn, layout: "auth" },
  [SIGN_IN]: { element: SignIn, layout: "auth" },
  [AGENCY_REGISTRATION]: { element: AgencyRegistration, layout: "auth" },
  [AGENT_REGISTRATION]: { element: AgentRegistration, layout: "auth" },
  [FORGOTTEN_PASSWORD]: { element: ForgottenPassword, layout: "auth" },
  [RESET_PASSWORD]: { element: ResetPassword, layout: "auth" },
};

const PRIVATE_ROUTES: IRouteElement = {
  [ROOT]: {
    element: AgentHome,
    layout: "main",
  },
  [SEARCH]: {
    element: Search,
    layout: "auth",
    title: t("search"),
  },
  [SEARCH_RESULTS]: {
    element: SearchResults,
    layout: "search",
    title: t("search results"),
    crumbParams: ["start_date", "end_date"],
  },
  [CRUISE]: {
    element: Cruise,
    layout: "search",
    title: t("number of staterooms"),
    crumbParams: ["start_date", "end_date"],
  },
  [ROOMS]: {
    element: Rooms,
    layout: "search",
    title: t("staterooms"),
    crumbParams: [
      "start_date",
      "end_date",
      "rooms",
      "room",
      "fare_1",
      "grade_1",
      "guests_1",
      "market_1",
    ],
  },
  [CABINS_SELECT]: {
    element: CabinSelect,
    layout: "search",
    title: t("cabins"),
    crumbParams: [
      "start_date",
      "end_date",
      "rooms",
      "room",
      "fare_*",
      "grade_*",
      "guests_*",
      "market_*",
      "cabin_*",
      "deck_*",
    ],
  },
  [PASSENGERS]: {
    element: Passengers,
    layout: "search",
    title: t("passengers"),
  },
  [PAYMENT]: {
    element:
      PAYMENT_PROVIDER === "authorize" ? AuthorizePayment : AirWallexPayment,
    layout: "search",
    title: t("payment"),
  },
  [CURRENT_BOOKINGS]: {
    element: CurrentBookings,
    layout: "main",
    title: t("Current bookings"),
  },
  [PREVIOUS_BOOKINGS]: {
    element: PreviousBookings,
    layout: "main",
    title: t("Previous bookings"),
  },
  [BOOKING]: {
    element: Booking,
    layout: "main",
    title: t("Booking"),
  },
  [PAYMENT_CONFIRMATION]: {
    element: PaymentConfirmation,
    layout: "search",
    title: i18n.t("payment_confirmation"),
  },
};

const PUBLIC_SIDE_MENU: ISideMenuRoute = {};

const PRIVATE_SIDE_MENU: ISideMenuRoute = {
  [ROOT]: {
    type: "link",
    label: () => t("agent home"),
  },
  [SEARCH]: {
    type: "link",
    label: () =>
      store.getState().environment.start_page_title ?? t("make a booking"),
  },
  [CURRENT_BOOKINGS]: {
    type: "link",
    label: () => store.getState().environment.current_page_title,
  },
  [PREVIOUS_BOOKINGS]: {
    type: "link",
    label: () => store.getState().environment.previous_page_title,
  },
  [LOGOUT]: {
    type: "button",
    label: () => t("logout"),
  },
};

const routes = {
  public: PUBLIC_ROUTES,
  private: PRIVATE_ROUTES,
  publicSideMenu: PUBLIC_SIDE_MENU,
  privateSideMenu: PRIVATE_SIDE_MENU,
};

export default routes;
