import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useLazyResetPasswordQuery } from "../../../store/services/SessionService";
import { showToast } from "../../../store/slices/toastSlice";
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
import TOAST from "../../../utils/constants/toast";
import Button from "../../shared/Button";
import Input from "../../shared/Input";

import styles from "./index.module.scss";
import SuccessfullyReset from "./molecules/SuccessfullyReset";

// Typing the structure for handling input changes.
type InputChangeHandler = Record<string, string>;

// Interface for validating input fields.
interface IValidationInputs {
  newPassword: string;
}

// Interface for managing input field states.
interface IInputs {
  newPassword: { value: string; errorMessage: string };
}

// ResetPassword component for resetting user password.
function ResetPassword() {
  // Hook for internationalization.
  const { t } = useTranslation();

  // Custom hook for dispatching actions to Redux store.
  const dispatch = useTypedDispatch();
  // Custom hook for making a query to reset password.
  const [resetPassword] = useLazyResetPasswordQuery();

  const { auth_page_background } = useTypedSelector(
    (state) => state.environment,
  );
  // Retrieving search parameters from the URL.
  const [searchParams] = useSearchParams();

  // State hooks for component state management.
  const [isSuccessfullyReset, setIsSuccessfullyReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState<IInputs>({
    newPassword: { value: "", errorMessage: "" },
  });

  // Extracting reset token from URL parameters.
  const reset_token = searchParams.get("reset_token");

  // Function to handle input changes and update state accordingly.
  const handleInputChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));
  };

  // Function to handle errors in form submission.
  const handleFormError = (errors: IValidationInputs) => {
    const updatedInputs = structuredClone(inputs);

    Object.keys(errors).forEach((errorKey) => {
      if (updatedInputs[errorKey as keyof IInputs]) {
        updatedInputs[errorKey as keyof IInputs].errorMessage =
          errors[errorKey as keyof IValidationInputs];
      }
    });

    setInputs(updatedInputs);
  };

  // Function to initiate password reset process.
  const setNewPassword = async (credentials: IValidationInputs) => {
    setIsLoading(true);

    const { isSuccess, isError } = await resetPassword({
      password: credentials.newPassword,
      reset_token: reset_token ?? "",
    });

    setIsLoading(false);

    if (isError) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: t("Something went wrong"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      handleFormError({
        newPassword: t("Something went wrong"),
      });
    }

    if (isSuccess) {
      dispatch(
        showToast({
          type: TOAST.SUCCESS_TYPE,
          message: t("Password successfully reset"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      setIsSuccessfullyReset(true);
    }
  };

  // Function to handle form submission.
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const isPasswordValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/.test(
      inputs.newPassword.value,
    );

    if (isPasswordValid) {
      setNewPassword({ newPassword: inputs.newPassword.value });
    } else {
      handleFormError({
        newPassword: t(
          "Please make sure your password matches the requirements below",
        ),
      });
    }
  };

  // Conditionally rendering the SuccessfullyReset component if reset was successful.
  if (isSuccessfullyReset) {
    return <SuccessfullyReset />;
  }

  // Rendering the ResetPassword component.
  return (
    <div
      className={styles.container}
      style={{ background: `url(${auth_page_background}) center/cover` }}>
      <div className={styles.content}>
        <p className={styles.title}>{t("reset your password")}</p>

        <p className={styles.subtitle}>
          {t("Please create a new password so you can login to your account.")}
        </p>

        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            value={inputs.newPassword.value}
            valueKey="newPassword"
            label={t("new password")}
            placeholder={t("Create new password")}
            autoComplete="new-password"
            errorMessage={inputs.newPassword.errorMessage}
            onChange={handleInputChange}
            secured
          />

          <div className={styles.passwordRules}>
            <p className={styles.subtitle}>{t("Password must")}</p>

            <ul className={styles.subtitle}>
              <li>{t("Be at least 8 characters")}</li>

              <li>
                {t(
                  "Include 1 Uppercase character, 1 Lower Case character and at " +
                    "least 1 number",
                )}
              </li>
            </ul>
          </div>

          <Button
            label={t("reset password")}
            type="submit"
            loading={isLoading}
          />
        </form>
      </div>

      <div className={styles.background} />
    </div>
  );
}

// Exporting the ResetPassword component as default.
export default ResetPassword;
