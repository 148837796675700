import classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  useState,
  useImperativeHandle,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";

import {
  GuestFields,
  updateGuest,
} from "../../../../../store/slices/guestsSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../../store/store";
import citizenshipList from "../../../../../utils/constants/citizenshipList";
import { DEFAULT_GUEST_FIELDS } from "../../../../../utils/constants/defaultGuestFields";
import genderList from "../../../../../utils/constants/genderlist";
import titleList from "../../../../../utils/constants/titleList";
import getDaysList from "../../../../../utils/helpers/getDaysList";
import getMonthsList from "../../../../../utils/helpers/getMonthsList";
import getYearsList from "../../../../../utils/helpers/getYearsList";
import { validateGuestForm } from "../../../../../utils/validation";
import Checkbox from "../../../../shared/Checkbox";
import Collapsible from "../../../../shared/Collapsible";
import CustomSelect from "../../../../shared/CustomSelect";
import DatePicker from "../../../../shared/DatePicker";
import Input from "../../../../shared/Input";
import SearchInput from "../../../../shared/SearchInput";
import TextArea from "../../../../shared/TextArea";

import styles from "./index.module.scss";

interface IRoomFormsProps {
  isLead: boolean;
  roomNumber: number;
  guestNumber: number;
}

interface ValidateFormOutput {
  inputs: GuestFields;
  errors: GuestFields | null;
}

const RoomForm = forwardRef(function RoomForm(
  { isLead, roomNumber, guestNumber }: IRoomFormsProps,
  ref: ForwardedRef<{
    validateForm: () => ValidateFormOutput;
  }>,
) {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();

  const { date_format } = useTypedSelector((state) => state.environment);
  const { guests } = useTypedSelector((state) => state.guests);

  const formRef = useRef<HTMLDivElement>(null);

  const guest = useMemo(
    () => guests[roomNumber]?.[guestNumber],
    [guests[roomNumber]?.[guestNumber]],
  );

  const {
    countryList,
    nationalitiesList,
    issueCountriesList,
    placeOfBirthCountriesList,
  } = useMemo(() => {
    const countryList = citizenshipList
      .map((el) => ({
        label: el.country,
        value: el.iso_2,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    const nationalitiesList = citizenshipList
      .map((el) => ({
        label: el.country,
        value: el.iso_3,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    const issueCountriesList = citizenshipList
      .map((el) => ({
        label: el.country,
        value: el.iso_2,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    const placeOfBirthCountriesList = citizenshipList
      .map((el) => ({
        label: el.country,
        value: el.iso_2,
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
      );

    return {
      countryList,
      nationalitiesList,
      issueCountriesList,
      placeOfBirthCountriesList,
    };
  }, []);

  const [countryListForm, setCountryListForm] = useState(countryList);
  const [genderListForm, setGenderListForm] = useState(genderList);
  const [titleListForm, setTitleListForm] = useState(titleList);

  const [nationalitiesListForm, setNationalitiesListForm] =
    useState(nationalitiesList);

  const [issueCountriesForm, setIssueCountriesForm] =
    useState(issueCountriesList);

  const [placeOfBirthCountriesForm, setPlaceOfBirthCountriesForm] = useState(
    placeOfBirthCountriesList,
  );

  const [inputs, setInputs] = useState<GuestFields>({
    ...{ ...DEFAULT_GUEST_FIELDS, ...(guest ?? {}) },
    lead_passenger: isLead,
  });

  const [errors, setErrors] = useState<GuestFields>({
    ...DEFAULT_GUEST_FIELDS,
    lead_passenger: isLead,
  });

  const [isExpanded, setIsExpanded] = useState(inputs.isExpanded ?? isLead);
  const [isSameAddressAsLead, setIsSameAddressAsLead] = useState(false);

  const { days, months, years } = useMemo(() => {
    const days = getDaysList(inputs.yearOfBirth, inputs.monthOfBirth).map(
      (item) => ({
        value: String(item),
        label: String(item),
      }),
    );

    const months = getMonthsList().map((item) => ({
      value: item,
      label: item,
    }));

    const years = getYearsList()
      .sort((a, b) => b - a)
      .map((item) => ({
        value: String(item),
        label: String(item),
      }));

    return { days, months, years };
  }, [inputs.yearOfBirth, inputs.monthOfBirth]);

  const handleExpand = (newIsExpanded: boolean) => setIsExpanded(newIsExpanded);

  const handleUpdateLeadAddress = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey: string;
  }) => {
    dispatch(
      updateGuest({
        stateroomNumber: 1,
        guestNumber: 1,
        guestFields: { ...inputs, [valueKey]: value },
      }),
    );
  };

  const handleInputChange = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey: string;
  }) => {
    setInputs((prev) => ({ ...prev, [valueKey]: value }));

    if (errors[valueKey as keyof GuestFields]) {
      setErrors((prev) => ({ ...prev, [valueKey]: "" }));
    }

    if (
      isLead &&
      ["address1", "address2", "city", "state", "zip_code", "country"].includes(
        valueKey,
      )
    ) {
      handleUpdateLeadAddress({
        value,
        valueKey,
      });
    }
  };

  const handleSearchInputChange = ({
    value,
    valueKey,
  }: {
    value: string;
    valueKey?: string;
  }) => {
    switch (valueKey) {
      case "title": {
        const filterTitleResult = titleList.filter(
          (titleLabel) =>
            titleLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            titleLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setTitleListForm(filterTitleResult);
        break;
      }

      case "gender": {
        const filterGenderResult = genderList.filter(
          (genderLabel) =>
            genderLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            genderLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setGenderListForm(filterGenderResult);
        break;
      }

      case "country": {
        const filterCountryResult = countryList.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setCountryListForm(filterCountryResult);
        break;
      }

      case "nationality": {
        const filterNationalitiesResult = nationalitiesList.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setNationalitiesListForm(filterNationalitiesResult);
        break;
      }

      case "country_of_issue": {
        const filterIssueCountries = issueCountriesList.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setIssueCountriesForm(filterIssueCountries);
        break;
      }

      case "place_of_birth": {
        const filterPlaceOfBirthCountries = placeOfBirthCountriesList.filter(
          (countryLabel) =>
            countryLabel.label.toLowerCase().includes(value.toLowerCase()) ||
            countryLabel.value.toLowerCase().includes(value.toLowerCase()),
        );

        setPlaceOfBirthCountriesForm(filterPlaceOfBirthCountries);
        break;
      }

      default: {
        break;
      }
    }
  };

  const onSameAddressAsLeadChange = ({ value }: { value: boolean }) => {
    setIsSameAddressAsLead(value);

    if (!isLead && !value) {
      setInputs((prev) => ({
        ...prev,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
      }));
    }
  };

  const updateErrors = (errors: GuestFields) => {
    setErrors((prev) => ({ ...prev, ...errors }));
  };

  const validateForm = useCallback(() => {
    const output: ValidateFormOutput = structuredClone({
      inputs: { ...inputs, isExpanded },
      errors: null,
    });

    const addressSource =
      !isLead && isSameAddressAsLead ? guests[1][1] : inputs;

    const country = countryList.find(
      (el) =>
        el.label === addressSource.country ||
        el.value === addressSource.country,
    );

    const nationality = nationalitiesList.find(
      (el) =>
        el.label === inputs.nationality || el.value === inputs.nationality,
    );

    const issueCountry = issueCountriesList.find(
      (el) =>
        el.label === inputs.country_of_issue ||
        el.value === inputs.country_of_issue,
    );

    const placeOfBirthCountry = placeOfBirthCountriesList.find(
      (el) =>
        el.label === inputs.place_of_birth ||
        el.value === inputs.place_of_birth,
    );

    const gender = genderList.find(
      (el) => el.label === inputs.gender || el.value === inputs.gender,
    );

    const address = {
      address1: addressSource.address1,
      address2: addressSource.address2,
      city: addressSource.city,
      state: addressSource.state,
      zip_code: addressSource.zip_code,
      country: addressSource.country,
    };

    validateGuestForm({
      data: {
        ...inputs,
        ...address,
        country: country?.value,
        nationality: nationality?.value,
        country_of_issue: issueCountry?.value,
        place_of_birth: placeOfBirthCountry?.value,
        gender: gender?.value,
      },
      onSuccess: (validData: GuestFields) => (output.inputs = validData),
      onError: (errors: GuestFields) => {
        setErrors(errors);
        output.errors = errors;
      },
    });

    return output;
  }, [guests, inputs, errors, isExpanded, isSameAddressAsLead]);

  const renderHeader = () => {
    return (
      <span className={styles.title}>
        {t("adult")} {guestNumber}
        {isLead && guestNumber === 1 && ` ${t("(lead guest)")}`}
      </span>
    );
  };

  useImperativeHandle(
    ref,
    () => ({
      validateForm,
      expand: () => setIsExpanded(true),
      collapse: () => setIsExpanded(false),
      setErrors: (errors: GuestFields) => updateErrors(errors),
      scrollIntoView: () => {
        const headerHeight = 100;
        const searchPanelHeight = 60;
        const elementPosition = formRef.current?.offsetTop ?? 0;

        const offsetPosition =
          elementPosition - headerHeight - searchPanelHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      },
    }),
    [validateForm],
  );

  return (
    <Collapsible
      ref={formRef}
      expanded={isExpanded}
      onExpand={handleExpand}
      renderHeader={renderHeader}>
      <div className={styles.form}>
        <div className={styles.inputs}>
          <SearchInput
            className={styles.input}
            value={inputs.title}
            valueKey="title"
            displayKey="label"
            results={titleListForm}
            label={t("Title")}
            placeholder={t("Select a title")}
            errorMessage={errors.title}
            isMultiple={false}
            onChange={handleSearchInputChange}
            onChosenChange={({ value, valueKey }) =>
              handleInputChange({ value: value.value, valueKey })
            }
            isRequired
          />
        </div>

        <div className={styles.inputs}>
          <Input
            className={styles.input}
            value={inputs.given_name}
            valueKey="given_name"
            label={t("first name")}
            placeholder={t("Please tell us your first name")}
            onChange={handleInputChange}
            errorMessage={errors.given_name}
            name="given_name"
            isRequired
          />

          <Input
            className={styles.input}
            value={inputs.last_name}
            valueKey="last_name"
            label={t("surname")}
            placeholder={t("Please tell us your last name")}
            onChange={handleInputChange}
            errorMessage={errors.last_name}
            name="last_name"
            isRequired
          />
        </div>

        <div className={styles.inputs}>
          <SearchInput
            className={styles.input}
            value={inputs.gender}
            valueKey="gender"
            displayKey="label"
            results={genderListForm}
            label="Gender"
            placeholder="Select a gender"
            errorMessage={errors.gender}
            isMultiple={false}
            onChange={handleSearchInputChange}
            onChosenChange={({ value, valueKey }) => {
              handleInputChange({ value: value.label, valueKey });
            }}
            isRequired
          />
        </div>

        <div className={classNames(styles.inputs, styles.inputs_dob)}>
          <CustomSelect
            className={styles.input}
            value={inputs.monthOfBirth}
            valueKey="monthOfBirth"
            label={t("month of birth")}
            placeholder={t("Month")}
            errorMessage={errors.monthOfBirth}
            items={months}
            onChange={handleInputChange}
            isRequired
          />

          <CustomSelect
            className={styles.input}
            value={inputs.dayOfBirth}
            valueKey="dayOfBirth"
            label={t("day of birth")}
            placeholder={t("Day")}
            errorMessage={errors.dayOfBirth}
            items={days}
            onChange={handleInputChange}
            isRequired
          />

          <CustomSelect
            className={styles.input}
            value={inputs.yearOfBirth}
            valueKey="yearOfBirth"
            label={t("year of birth")}
            placeholder={t("Year")}
            errorMessage={errors.yearOfBirth}
            items={years}
            onChange={handleInputChange}
            isRequired
          />
        </div>

        <div className={styles.inputs}>
          <SearchInput
            className={styles.input}
            value={inputs.nationality}
            valueKey="nationality"
            displayKey="label"
            results={nationalitiesListForm}
            label={t("citizenship")}
            placeholder={t("Enter your Citizenship")}
            errorMessage={errors.nationality}
            isLoading={false}
            isMultiple={false}
            onChange={handleSearchInputChange}
            onChosenChange={({ value, valueKey }) =>
              handleInputChange({ value: value.label, valueKey })
            }
            isRequired
          />
        </div>

        {(!isLead || (isLead && guestNumber !== 1)) && (
          <div className={styles.inputs}>
            <Checkbox
              label={t(" The same address as lead guest")}
              value={isSameAddressAsLead}
              onChange={onSameAddressAsLeadChange}
            />
          </div>
        )}

        {((isLead && guestNumber === 1) || !isSameAddressAsLead) && (
          <>
            <div className={styles.inputs}>
              <Input
                className={styles.input}
                value={inputs.address1}
                valueKey="address1"
                label={t("Address Line 1")}
                placeholder={t("Your address first line")}
                onChange={handleInputChange}
                errorMessage={errors.address1}
                name="address1"
                isRequired
              />

              <Input
                className={styles.input}
                value={inputs.address2}
                valueKey="address2"
                label={t("Address Line 2")}
                placeholder={t("Your address second line")}
                onChange={handleInputChange}
                errorMessage={errors.address2}
                name="address2"
              />
            </div>

            <div className={styles.inputs}>
              <Input
                className={styles.input}
                value={inputs.city}
                valueKey="city"
                label={t("city")}
                placeholder={t("City you live in")}
                onChange={handleInputChange}
                errorMessage={errors.city}
                showError={!!errors.city}
                name="city"
                isRequired
              />

              <Input
                className={styles.input}
                value={inputs.state}
                valueKey="state"
                label={t("state")}
                placeholder={t("State you live in")}
                onChange={handleInputChange}
                errorMessage={errors.state}
                showError={!!errors.state}
                name="state"
                isRequired
              />
            </div>

            <p className={classNames(styles.text, styles.text_small)}>
              {t(
                "Please be advised that we will verify phone numbers and zip/postal codes against the country.",
              )}
            </p>

            <div className={styles.inputs}>
              <Input
                className={styles.input}
                value={inputs.zip_code}
                valueKey="zip_code"
                label={t("zip code")}
                placeholder={t("Zipcode")}
                onChange={handleInputChange}
                errorMessage={errors.zip_code}
                name="zipCode"
                isRequired
              />

              <SearchInput
                className={styles.input}
                value={inputs.country}
                valueKey="country"
                displayKey="label"
                results={countryListForm}
                label={t("country")}
                placeholder={t("Select a country")}
                errorMessage={errors.country}
                isLoading={false}
                isMultiple={false}
                onChange={handleSearchInputChange}
                onChosenChange={({ value, valueKey }) =>
                  handleInputChange({ value: value.label, valueKey })
                }
                isRequired
              />
            </div>
          </>
        )}

        <div className={styles.inputs}>
          <Input
            className={styles.input}
            value={inputs.email}
            valueKey="email"
            label={t("email address")}
            placeholder={t("Passenger email")}
            onChange={handleInputChange}
            errorMessage={errors.email}
            name="email"
            isRequired
          />

          <Input
            className={styles.input}
            value={inputs.phone}
            valueKey="phone"
            label={t("phone number")}
            placeholder={t("Passenger phone number")}
            onChange={handleInputChange}
            errorMessage={errors.phone}
            name="phone"
            isRequired
          />
        </div>

        <div className={styles.textContainer}>
          <p className={styles.title}>{t("passport details (optional)")}</p>

          <p className={styles.text}>
            {t(
              "Passport details are optional but please make sure any details added correctly match that of the passport document.",
            )}
          </p>
        </div>

        <div className={styles.inputs}>
          <Input
            className={styles.input}
            value={inputs.passport_number}
            valueKey="passport_number"
            label={t("passport number")}
            placeholder={t("Passport Number")}
            onChange={handleInputChange}
            errorMessage={errors.passport_number}
            name="passportNumber"
          />
        </div>

        <div className={styles.inputs}>
          <DatePicker
            className={styles.input}
            defaultDate={[inputs.issue_date]}
            valueKey="issue_date"
            label={t("issue date")}
            placeholder={date_format || "MM/DD/YYYY"}
            errorMessage={errors.issue_date}
            displayFormat="M-d-Y"
            returnFormat={date_format || "MM/DD/YYYY"}
            mode="single"
            select="day"
            name="issueDate"
            onChange={({ value, valueKey }) =>
              handleInputChange({
                value: value[0],
                valueKey: valueKey ?? "issue_date",
              })
            }
          />

          <DatePicker
            className={styles.input}
            defaultDate={[inputs.expiry_date]}
            valueKey="expiry_date"
            label={t("expiry date")}
            placeholder={date_format || "MM/DD/YYYY"}
            errorMessage={errors.expiry_date}
            displayFormat="M-d-Y"
            returnFormat={date_format || "MM/DD/YYYY"}
            mode="single"
            select="day"
            name="expiryDate"
            onChange={({ value, valueKey }) =>
              handleInputChange({
                value: value[0],
                valueKey: valueKey ?? "expiry_date",
              })
            }
          />
        </div>

        <div className={styles.inputs}>
          <SearchInput
            className={styles.input}
            value={inputs.place_of_birth}
            valueKey="place_of_birth"
            displayKey="label"
            results={placeOfBirthCountriesForm}
            label={t("place of birth")}
            placeholder={t("Place of Birth")}
            errorMessage={errors.place_of_birth}
            isLoading={false}
            isMultiple={false}
            onChange={handleSearchInputChange}
            onChosenChange={({ value, valueKey }) =>
              handleInputChange({ value: value.label, valueKey })
            }
          />

          <SearchInput
            className={styles.input}
            value={inputs.country_of_issue}
            valueKey="country_of_issue"
            displayKey="label"
            results={issueCountriesForm}
            label={t("country of issue")}
            placeholder={t("Country of Issue")}
            errorMessage={errors.country_of_issue}
            isLoading={false}
            isMultiple={false}
            onChange={handleSearchInputChange}
            onChosenChange={({ value, valueKey }) =>
              handleInputChange({ value: value.label, valueKey })
            }
          />
        </div>

        <TextArea
          className={styles.input}
          value={inputs.request}
          valueKey="request"
          label={t("request")}
          subtext={t(
            "Please be advised that all requests may not be able to be accommodated.",
          )}
          placeholder={t("Request")}
          onChange={handleInputChange}
          errorMessage={errors.request}
          showError={!!errors.request}
          name="request"
        />
      </div>
    </Collapsible>
  );
});

export default RoomForm;
