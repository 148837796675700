// TYPES
export type ILayoutType = "auth" | "main" | "search";
export type PaymentProvider = "authorize" | "airwallex";

export type IRouteElement = Record<
  string,
  {
    element: React.FC<React.PropsWithChildren>;
    layout: ILayoutType;
    title?: string;
    crumbParams?: string[];
  }
>;

export type ISideMenuRoute = Record<
  string,
  {
    type: string;
    label: () => string;
  }
>;

export type RouteParams = string | Array<[string, string]>;

export const routeParams = (path: string, params: RouteParams): string => {
  if (typeof params === "string") {
    path = path.replace(/:([a-z0-9\-_.~]+(\??)(\/|:|$))/gi, params + "/");

    if (path[path.length - 1] === "/") {
      path = path.substring(0, path.length - 1);
    }
  } else {
    for (const [key, value] of params) {
      path = path.replace(`:${key}`, value).replace(`:${key}?`, value);
    }
  }

  return path;
};

// PUBLIC ROUTES
export const ROOT = "/";
export const SIGN_IN = `${ROOT}sign-in`;
export const AGENCY_REGISTRATION = `${ROOT}agency-registration`;
export const AGENT_REGISTRATION = `${ROOT}agent-registration`;
export const FORGOTTEN_PASSWORD = `${ROOT}forgotten-password`;
export const RESET_PASSWORD = `${ROOT}agent_reset_password`;

// PRIVATE ROUTES
export const SEARCH = `${ROOT}search`;
export const SEARCH_RESULTS = `${ROOT}search-results`;
export const CRUISE = `${SEARCH_RESULTS}/:cruiseId`;
export const ROOMS = `${CRUISE}/rooms`;
export const CABINS_SELECT = `${CRUISE}/cabin-select`;
export const PASSENGERS = `${CRUISE}/passengers`;
export const PAYMENT = `${CRUISE}/payment`;
export const CURRENT_BOOKINGS = `${ROOT}current-bookings`;
export const PREVIOUS_BOOKINGS = `${ROOT}previous-bookings`;
export const BOOKING = `${ROOT}booking/:pnr`;
export const PAYMENT_CONFIRMATION = `${CRUISE}/payment-confirmation`;

// SIDE MENU ROUTES
export const LOGOUT = "LOGOUT";
