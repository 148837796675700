import {
  ReservationInterface,
  ReservationListInterface,
} from "../slices/reservationSlice"; // Importing necessary interfaces from reservationSlice file
import { store } from "../store";

import { baseApi } from "./apiSingleton"; // Importing baseApi from apiSingleton file

// Interface defining the shape of the request to initialize reservation list
export interface IInitReservationListRequest {
  channel: string;
  channel_partner: string;
  agency: string;
  ship_code?: string;
  sailing_code?: string;
  lastname?: string;
  embarkation_from?: string;
  embarkation_to?: string;
}

interface ConfirmReservationRequest {
  pnr: string;
  transactions: Array<{
    transaction_type: "creditcard";
    made_on?: string;
    made_by?: "Website";
    amount?: number;
    currency?: string;
    card_clearance: {
      clearance_system: "airwallex";
      auth_code: string;
      transaction_id: string;
      other: {
        card_type: string;
        last_four: string;
        transaction_id: string;
        intent_id: string;
      };
    };
  }>;
}

// Response type for the reservation list
export type ReservationListResponse = ReservationListInterface[];

// Creating ReservationApi by injecting endpoints into baseApi
export const ReservationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Defining endpoint for initializing reservation list
    initReservationList: builder.query<
      ReservationListResponse,
      IInitReservationListRequest
    >({
      query: (params) => ({
        url: `res/reservation-list/`, // API endpoint for initializing reservation list
        method: "GET", // HTTP method
        params, // Request parameters
      }),
    }),

    // Defining endpoint for retrieving a specific reservation
    retrieveReservation: builder.query<
      ReservationInterface,
      { pnr: string; channel_partner: string; agency: string }
    >({
      query: (params) => ({
        url: `/res/reservation-list/${params.pnr}/?channel=CS&channel_partner=${params.channel_partner}&agency=${params.agency}`, // API endpoint for retrieving reservation
        method: "GET", // HTTP method
      }),
    }),
    confirmReservation: builder.query<null, ConfirmReservationRequest>({
      query: (request) => {
        const { agency: agent, agent: agency } = store.getState().session;

        const { pnr, ...body } = request;

        return {
          url: `/res/reservation/${pnr}/`,
          method: "PUT",
          body: {
            channel: "CS",
            agency,
            agent,
            ...body,
          },
        };
      },
    }),
  }),
});

// Destructuring useful hooks from ReservationApi
export const {
  useLazyInitReservationListQuery,
  useLazyRetrieveReservationQuery,
  useLazyConfirmReservationQuery,
} = ReservationApi;
