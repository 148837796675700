import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Import necessary modules

import { CabinSelectAPI, Cabin } from "../services/CabinSelectService"; // Importing CabinSelectAPI and Cabin from CabinSelectService

import { Deck, Grade, Price } from "./searchSlice"; // Importing types from searchSlice

// Define the shape of a Payment Schedule Item
export interface PaymentScheduleItem {
  amount: string;
  currency: string;
  date: string;
}

// Define the shape of a Pricing object
interface Pricing {
  payment_schedule?: PaymentScheduleItem[]; // Array of Payment Schedule Items
  total_price?: string; // Total price as a string
  // Array of guest information
  guests?: Array<{
    discount: string;
    gft: string;
    guest: number;
    nccf: string;
    total_fare: string;
    total_price: string;
  }>;
}

// Define the shape of a Room object
export interface Room {
  fare?: Price; // Price information
  marketType?: string; // Market type
  grade?: Grade; // Grade information
  guestsNumber?: number; // Number of guests
  cabin?: Cabin; // Cabin information
  deck?: Deck; // Deck information
  pricing?: Pricing; // Pricing information
  pnr?: string; // PNR (Passenger Name Record)
}

// Define the shape of the Rooms object, which is a record of Room objects indexed by room number
export type Rooms = Record<number, Room>;

// Define the shape of the Rooms state
export interface RoomsState {
  rooms?: Rooms; // Rooms information
  cabins?: Cabin[]; // Cabin information
  isCabinsLoading: boolean; // Flag indicating whether cabins are loading
}

// Define the initial state for Rooms slice
const initialState: RoomsState = {
  rooms: undefined,
  cabins: undefined,
  isCabinsLoading: true,
};

// Create Rooms slice using createSlice function
const roomsSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    // Define reducer actions
    updateRoomsState(state: RoomsState, action: PayloadAction<RoomsState>) {
      state.rooms = action.payload.rooms;
      state.cabins = action.payload.cabins;
    },
    updateRooms(state: RoomsState, action: PayloadAction<Rooms>) {
      state.rooms = action.payload;
    },
    updateRoom(
      state: RoomsState,
      action: PayloadAction<{ room: Room; roomNumber: number }>,
    ) {
      if (state.rooms) {
        state.rooms[action.payload.roomNumber] = action.payload.room;
      }
    },
  },
  extraReducers: (builder) => {
    // Define extra reducers for handling asynchronous actions
    builder
      .addMatcher(CabinSelectAPI.endpoints.initCabins.matchPending, (state) => {
        state.isCabinsLoading = true;
      })
      .addMatcher(
        CabinSelectAPI.endpoints.initCabins.matchFulfilled,
        (state, action) => {
          state.cabins = action.payload || []; // Update cabins with the payload
          state.isCabinsLoading = false; // Set isCabinsLoading flag to false
        },
      )
      .addMatcher(
        CabinSelectAPI.endpoints.initCabins.matchFulfilled,
        (state) => {
          state.isCabinsLoading = false; // Set isCabinsLoading flag to false
        },
      );
  },
});

// Export actions from Rooms slice
export const { updateRoomsState, updateRooms, updateRoom } = roomsSlice.actions;

// Export the reducer function for Rooms slice
export default roomsSlice.reducer;
