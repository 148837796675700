import { ROOT } from "../../../utils/constants/routes";
import Logo from "../Logo";

import styles from "./index.module.scss";

// Footer component definition.
function Footer() {
  // Render footer with a container style and include the Logo component,
  // which uses the ROOT constant for its pathname prop, likely directing to the homepage.
  return (
    <footer className={styles.container}>
      <Logo pathname={ROOT} />
    </footer>
  );
}

// Make the Footer component available for import.
export default Footer;
