import { nanoid } from "@reduxjs/toolkit"; // Import unique identifier generator from Redux Toolkit
import classNames from "classnames"; // Import classNames library for conditional class application
import { t } from "i18next"; // Import translation function from i18next

import { useTypedSelector } from "../../../../../store/store"; // Import Typed Selector hook from Redux store
import { currencyToFormat } from "../../../../../utils/helpers/currency"; // Import utility function for formatting currency

import styles from "./index.module.scss"; // Import component styles

/**
 * StateroomPriceBreakdown component displays a breakdown of pricing for a given stateroom.
 *
 * @param {number} roomNumber - The number of the stateroom.
 * @returns {JSX.Element} StateroomPriceBreakdown component JSX elements.
 */
function StateroomPriceBreakdown({ roomNumber }: { roomNumber: number }) {
  // Retrieve prices from Redux store using Typed Selector hook
  const prices = useTypedSelector((state) => state.prices);

  // Render component JSX
  return (
    <div className={styles.body}>
      {/* Iterate over each guest in the room */}
      <div key={nanoid()} className={styles.room}>
        <table>
          <tbody>
            <tr>
              {/* Display number of guests */}
              <td>
                <p className={styles.tableHeading}>{t("guests")}</p>
              </td>

              <td>
                <p className={styles.tableValue}>
                  {`${prices?.guests.length ?? "N/A"} ${t("adult(s)")}`}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <hr className={styles.line} />

        <div className={styles.guests}>
          {/* Display pricing details for each guest */}
          {prices.guests.map((guest) => {
            const { guest: guestNumber, total_fare, gft, total_price } = guest;

            return (
              <div key={guestNumber} className={styles.guest}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {/* Display fare for each guest */}
                        <p
                          className={classNames(
                            styles.tableHeading,
                            styles.guestHeading,
                          )}>
                          fare
                        </p>
                      </td>

                      <td>
                        <p
                          className={classNames(
                            styles.tableValue,
                            styles.guestValue,
                          )}>
                          {/* Format fare currency */}
                          {currencyToFormat(
                            total_fare ?? 0,
                            prices.currency ?? "USD",
                          )}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        {/* Display tax for each guest */}
                        <p
                          className={classNames(
                            styles.tableHeading,
                            styles.guestHeading,
                          )}>
                          tax
                        </p>
                      </td>

                      <td>
                        <p
                          className={classNames(
                            styles.tableValue,
                            styles.guestValue,
                          )}>
                          {/* Format tax currency */}
                          {currencyToFormat(gft, prices.currency ?? "USD")}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p
                          className={
                            styles.tableHeading
                          }>{`${t("guest")} ${guestNumber} ${t("subtotal")}`}</p>
                      </td>

                      <td>
                        {/* Display total cost for each guest */}
                        <p className={styles.tableValue}>
                          {/* Format total currency */}
                          {currencyToFormat(
                            total_price,
                            prices.currency ?? "USD",
                          )}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr className={styles.line} />
              </div>
            );
          })}
        </div>

        <table>
          <tbody>
            <tr>
              <td>
                {/* Display total fare for the stateroom */}
                <p
                  className={
                    styles.tableHeading
                  }>{`${t("stateroom")} ${roomNumber} ${t("fare")}:`}</p>
              </td>

              <td>
                <p className={styles.tableValue}>
                  {/* Format total fare currency */}
                  {currencyToFormat(
                    prices.total_price,
                    prices.currency ?? "USD",
                  )}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <hr className={styles.line} />
      </div>
    </div>
  );
}

// Export the component
export default StateroomPriceBreakdown;
